/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COUNTRIES_LIST, NOVARTIS_PROCESS, TH_SERVIER_PROCESS, TH_SPR_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';
import { DrugSearchListConvertedData, DrugSelectedData, SelectedDrugStrength } from 'projects/nga-PAP/src/app/featureModules/dashboard/featureModules/home/models/prescription-detail';
import { DrugSearchListData } from 'projects/nga-PAP/src/app/models/responses.model';
import { Subscription } from 'rxjs';
import * as HomeAction from 'projects/nga-PAP/src/app/featureModules/dashboard/featureModules/home/store/actions/home.actions';
import * as HomeSelector from 'projects/nga-PAP/src/app/featureModules/dashboard/featureModules/home/store/selectors/home.selectors';
import { Store } from '@ngrx/store';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-concomitant-medication-info-form',
  templateUrl: './concomitant-medication-info-form.component.html',
  styleUrls: ['./concomitant-medication-info-form.component.scss']
})
export class ConcomitantMedicationInfoFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;
  /**
   * Form pediatric report.
   */
  public concomitantMedInfoForm: FormGroup;
  private disabled: boolean;
  public drugSearchArray: DrugSearchListConvertedData[] = [];
  /**
   * Variable to select search string.
   */
  public toHighlight: string;
  /**
   * used to subscribe the drug search data.
   */
  public drugSearchSub: Subscription;
  /**
   *  used to show the drug search result.
   */
   public drugSearchResults: DrugSearchListData[];
  /**
   * used to create selected drug strength json string to array object.
   */
  public selectedDrugStrengthObj: Array<SelectedDrugStrength>;
  /**
   * used to store the selected drug data
   */
   public selectedDrugData: DrugSelectedData;
  /**
   * used to set selected drug in a format.
   */
  public selectedDrugValue: any;
  public invalidDrug = false;
  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.concomitantMedInfoForm.controls; }
  get formValidity(): boolean { return this.concomitantMedInfoForm.valid; }
  get formValues(): { concomitantMedInfoForm: any; } {
    return {
      concomitantMedInfoForm: this.concomitantMedInfoForm.value
    };
  }

  /**
   *
   */
  drugStrengthUnitList = [
    { id: 1, name: 'mg' },
    { id: 2, name: 'ug' },
    { id: 3, name: 'g' },
    { id: 4, name: 'mg/mL' },
    { id: 5, name: 'IU/mL' },
    { id: 6, name: '%' }
  ];
  administrationRouteList = [
    { id: 1, name: 'Oral' },
    { id: 2, name: 'Intravenous, IV' },
    { id: 3, name: 'Intramuscular, IM' },
    { id: 4, name: 'Subcutaneous, SC' },
    { id: 5, name: 'Intraocular' },
    { id: 6, name: 'NA' }
  ];

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder, private store: Store) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.concomitantMedInfoForm = this.fb.group({

      drugName: new FormControl(
        {value : this.formData?.drugName ? this.formData?.drugName : '', disabled: this.disabled},
        [Validators.maxLength(100)]),
      drugStrength: new FormControl(
        {value : this.formData?.drugStrength ? this.formData?.drugStrength : '', disabled: this.disabled}),
      drugStrengthUnit: new FormControl(
        {value : this.formData?.drugStrengthUnit ? this.formData?.drugStrengthUnit : 'mg',
       disabled: this.disabled}),
      administrationRoute: new FormControl(
        {value : this.formData?.administrationRoute ? this.formData?.administrationRoute : '', disabled: this.disabled},
        Validators.maxLength(1000)),
      dosage: new FormControl(
        {value : this.formData?.dosage ? this.formData?.dosage : '', disabled: this.disabled},
        [Validators.maxLength(100)]),
        dailyDosing: new FormControl(
          {value : this.formData?.dailyDosing ? this.formData?.dailyDosing : '', disabled: this.disabled},
         ),
      frequency: new FormControl(
        {value : this.formData?.frequency ? this.formData?.frequency : '', disabled: this.disabled},
        Validators.maxLength(20)),
      treatmentStartDate: new FormControl(
        {value : this.formData?.treatmentStartDate ? this.formData?.treatmentStartDate : '', disabled: this.disabled},
        Validators.maxLength(50)),
      treatmentEndDate: new FormControl(
        {value : this.formData?.treatmentEndDate ? this.formData?.treatmentEndDate : '', disabled: this.disabled}),
      indicationForUse: new FormControl(
        {value : this.formData?.indicationForUse ? this.formData?.indicationForUse : '', disabled: this.disabled},
       this.checkForSingaporeCountry() ? Validators.maxLength(1000) : Validators.maxLength(50)),
      batchNoAndExpiryDate: new FormControl(
        {value : this.formData?.batchNoAndExpiryDate ? this.formData?.batchNoAndExpiryDate : '',
         disabled: this.disabled},
        Validators.maxLength(50)),
      formulation: new FormControl(
        {value : this.formData?.formulation ? this.formData?.formulation : '', disabled: this.disabled},
        Validators.maxLength(50))
    });
    if (this.formData?.drugName) {
      this.selectedDrugValue = this.formData?.drugName;
    }
    if (this.checkForServierProcess())
      {
        this.concomitantMedInfoForm.removeControl('formulation');
        this.concomitantMedInfoForm.removeControl('frequency');
      }
  }

  /**
   * Check for a particular process
   */
  checkForProcess(): boolean {
    return this.process === TH_SPR_PROCESS;
  }
  /**
   * Check if process is for servier chatbot flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
  checkForNovartis(): boolean {
    return this.process === NOVARTIS_PROCESS;
  }
  checkForSingaporeCountry(): boolean {
    return  localStorage.getItem('country') === COUNTRIES_LIST.SINGAPORE.value;
  }
  checkForThailandCountry(): boolean {
    return  localStorage.getItem('country') === COUNTRIES_LIST.THAILAND.value;
  }

  getMaxLength(): number {
    if (this.checkForSingaporeCountry()) {
      return 1000;
    } else if (this.checkForNovartis()){
      return 500;
    }else {
      return 50;
    }
  }

 /**
  * search drug
  */
    searchDrug(event): void {
      this.toHighlight = event.query;
      this.store.dispatch(HomeAction.resetDrugList());
      this.store.dispatch(HomeAction.loadDrugList(
        {
          keyword: event.query
        }
      ));

      this.drugSearchSub = this.store.select(HomeSelector.getSearchDrugList).subscribe(resData => {
        if (resData !== null) {
          this.invalidDrug = false;
          this.drugSearchResults = resData.list;
          this.drugSearchArray = [];
          if (this.drugSearchResults !== null) {
            this.drugSearchResults.forEach(element => {
              this.selectedDrugStrengthObj = JSON.parse(element.strength);
              this.selectedDrugStrengthObj.forEach(strength => {
                this.drugSearchArray.push({
                  id: element.id,
                  name: element.brandName,
                  genericName: element.genericName,
                  strength: {
                    unit: {
                      id: 1,
                      name: strength.Unit
                    },
                    value: strength.Value
                  },
                  dosageForm: element.dosageForm,
                  routeOfAdministration: element.route
                });
              });
            });
          }
        }
        if (this.drugSearchResults == null){
          this.invalidDrug = true;
       }
      });
    }
    selectedDrug(event): void {
      this.selectedDrugData = event;
      this.selectedDrugValue = event.genericName + ' (' + event.name + ')';
      this.concomitantMedInfoForm.get('drugStrength').setValue(event.strength.value);
      this.concomitantMedInfoForm.get('administrationRoute').setValue(event.routeOfAdministration);
      this.concomitantMedInfoForm.get('formulation').setValue(event.dosageForm);
    }
}

<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'ADVERSE_EVENTS_INFORMATION' | translate}}</h5>
  <form [formGroup]="adverseEventInfoForm">
    <div class="row">
      <div class="col-md-12" *ngIf="!checkForServierProcess() && !checkForSingaporeCountry()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"ADVERSE_EVENTS" | translate}}
              <span class="text-danger">*</span>
            </span>
            <span class="fnt-size12px">
              {{!form.aeOverview?.value.length ? 0 :
              form.aeOverview?.value.length}}/500
            </span>
          </label>
          <div class="position-relative">
            <input (keyup)="userInput('aeOverview', $event)" formControlName="aeOverview" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_OVERVIEW' | translate}}" [maxlength]="500" />
          </div>
          <div *ngIf="form.aeOverview?.touched && form.aeOverview?.invalid" class="text-danger fnt-size12px">
            {{"ADVERSE_EVENTS" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="checkForSingaporeCountry()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{'ADVERSE_EVENT'| translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <textarea formControlName="aeOverview" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_DESC_HERE' | translate}}" [maxlength]="10000">
            </textarea>
          </div>
          <div *ngIf="form.aeOverview.touched && form.aeOverview.invalid"
            class="text-danger fnt-size12px">
            {{(checkForSingaporeCountry() ? "ADVERSE_EVENT" :"DESC_ADVERSE_EVENT") | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="!checkForSingaporeCountry()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{(checkForServierProcess() ? "OBSVD_ADVERSE_EVENT" :"DESC_ADVERSE_EVENT") | translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <textarea (keyup)="userInput('descriptionAdverseEvent', $event)" formControlName="descriptionAdverseEvent" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_DESC_HERE' | translate}}" [maxlength]="10000">
            </textarea>
          </div>
          <div *ngIf="form.descriptionAdverseEvent.touched && form.descriptionAdverseEvent.invalid"
            class="text-danger fnt-size12px">
            {{(checkForSingaporeCountry() ? "ADVERSE_EVENT" :"DESC_ADVERSE_EVENT") | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      
      <div class="col-md-4" *ngIf="checkForSingaporeCountry()">
        <div class="form-group">
          <label class="text-secondary-grey fnt-size14px">
            {{'AE_TYPE' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <select class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
            formControlName="aeType">
            <option value='' disabled selected class="d-none">
              {{'SELECT_TYPE' | translate}}
            </option>
            <option *ngFor="let form of aeType" [value]="form.name"
              [selected]="form.name === form.aeType?.value" class="mt-5">
              {{form.name}}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-4" *ngIf="checkForSingaporeCountry()">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size14px">
            {{"DATE_OF_DEATH" | translate}}
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.dateOfDeath.touched && form.dateOfDeath.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="dateOfDeath" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="checkForSingaporeCountry()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"CAUSE_OF_DEATH"| translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="eventCausality" type="text" class="form-control fnt-size14px"
              placeholder="{{'MENTION_CAUSE_OF_DEATH' | translate}}" [maxlength]="500" />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size14px">
            {{(checkForServierProcess() ? "ONSET_DATE" :"EVENT_ONSET_DATE") | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.symptomOnsetDate.touched && form.symptomOnsetDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="symptomOnsetDate" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.symptomOnsetDate.touched ) && form.symptomOnsetDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{(checkForServierProcess() ? "ONSET_DATE" :"EVENT_ONSET_DATE") | translate}}
            {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size14px" *ngIf="!checkForSingaporeCountry()">
            {{(checkForServierProcess() ? "UNTIL(" :"EVENT_END_DATE") | translate}}
            {{(checkForServierProcess() ? "IF_RECOVERED)" :"") | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <label class="fnt-size14px" *ngIf="checkForSingaporeCountry()">{{'RESOLUTION_DATE'|translate}}</label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.symptomEndDate.touched && form.symptomEndDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'" formControlName="symptomEndDate"
              placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.symptomEndDate.touched ) && form.symptomEndDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{'EVENT_END_DATE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="fnt-size14px">
            {{checkForSingaporeCountry() ? (getFieldName('outcome') | translate): 'OUTCOME' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <select [(ngModel)]="selectedOutcome" class="form-control fnt-size12px fnt_Medium bg-porcelain-white" formControlName="outcome" (keyup.backspace)="selectedOutcome = ''">
            <option value='' disabled selected class="d-none">
              {{'SELECT_OUTCOME' | translate}}
            </option>
            <option *ngFor="let form of outcomeList" [value]="form.name" [selected]="form.name === form.outcome?.value"
              class="mt-5">
              {{form.name}}
            </option>
          </select>
          <div *ngIf="(form.outcome.touched) && form.outcome.invalid" class="d-none text-danger fnt-size10px">
            {{'OUTCOME' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess() && !checkForSingaporeCountry()">
        <div class="form-group">
          <label class="fnt-size14px">
            {{'EVENT_CAUSALITY' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <select class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
            formControlName="eventCausality">
            <option value='' disabled selected class="d-none">
              {{'SELECT_CAUSALITY' | translate}}
            </option>
            <option *ngFor="let form of eventCausalityList" [value]="form.name"
              [selected]="form.name === form.eventCausality?.value" class="mt-5">
              {{form.name}}
            </option>
          </select>
          <div *ngIf="(form.eventCausality?.touched) && form.eventCausality?.invalid"
            class="d-none text-danger fnt-size10px">
            {{'EVENT_CAUSALITY' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="fnt-size14px">
            {{(checkForServierProcess() ? "SERIOUS" :"SERIOUSNESS_CRITERIA") | translate}}
            <span *ngIf="checkForSingaporeCountry()" class="text-danger">*</span>
          </label>
          <select [(ngModel)]="selectedSeriousCriteria" class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
            formControlName="seriousnessCriteria" (keyup.backspace)="selectedSeriousCriteria = ''">
            <option value='' disabled selected class="d-none">
              {{'SELECT_SERIOUSNESS_CRITERIA' | translate}}
            </option>
            <option *ngFor="let form of seriousnessCriteriaList" [value]="form.name"
              [selected]="form.name === form.seriousnessCriteria?.value" class="mt-5">
              {{form.name}}
            </option>
          </select>
          <div *ngIf="(form.seriousnessCriteria.touched) && form.seriousnessCriteria.invalid && checkForSingaporeCountry()"
            class="text-danger fnt-size12px">
            {{(checkForServierProcess() ? "SERIOUS" :"SERIOUSNESS_CRITERIA") | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"GENERAL_DISEASES" | translate}}/{{"CONCOMITANT_DISEASES" | translate}}
            </span>
          </label>
          <div class="position-relative">
            <input (keyup)="userInput('genDisease', $event)" formControlName="genDisease" type="text" class="form-control fnt-size14px"
              placeholder="{{'YEAR_OF_DIAGNOSE' | translate}}" [decimal]="false"/>
          </div>
          <div *ngIf="form?.genDisease?.touched && form?.genDisease?.invalid" class="d-none text-danger fnt-size12px">
            {{"GENERAL_DISEASES" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="checkForServierProcess()">
        <div class="form-group">
          <label class="fnt-size14px">
            {{'CAS_DRUG_RELATION' | translate}}
          </label>
          <select class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
            formControlName="drgRelation">
            <option value='' disabled selected class="d-none">
              {{'SELECT_RELATION' | translate}}
            </option>
            <option *ngFor="let form of casualRelationShipList" [value]="form.name"
              [selected]="form.name === form.drgRelation?.value" class="mt-5">
              {{form.name}}
            </option>
          </select>
          <div *ngIf="(form?.drgRelation?.touched) && form?.drgRelation?.invalid"
            class="d-none text-danger fnt-size10px">
            {{'DRG_RELATION' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-8" *ngIf="checkForSingaporeCountry()">
        <div class="form-group">
          <label class="mb-1 fnt-size14px d-flex justify-content-between">
            <span>
              {{"ADD_AE_INFO" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
            <span class="fnt-size12px">
              {{ !form.descriptionAdverseEvent.value.length ? 0 : form.descriptionAdverseEvent.value.length }}/1000
            </span>
          </label>
          <div class="position-relative">
            <textarea 
              formControlName="descriptionAdverseEvent" 
              class="form-control fnt-size14px"
              placeholder="{{'ENTER_MORE_INFO' | translate}}" 
              [maxlength]="1000">
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

/**
 * Ngrx store imports.
 */
import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import * as fromLogin from './login/login.reducer';
import * as fromSignup from './signup/signup.reducer';
import * as fromError from './error/error.reducer';
import * as fromFile from './file/file.reducer';
import * as fromHybrid from './hybrid/hybrid.reducer';
import * as fromDoctor from './doctor/doctor.reducer';
import * as fromPharma from './pharma/pharma.reducer';
/**
 * Environment imports.
 */
import { environment } from '../../../environments/environment';
import { from } from 'rxjs';
/**
 * Interface for reducers map.
 */
export interface State {
  login: fromLogin.State;
  file: fromFile.State;
  signup: fromSignup.State;
  hybrid: fromHybrid.State;
  doctor: fromDoctor.State;
  error: fromError.State;
  pharma: fromPharma.State;
}

/**
 * Action reducer mapping.
 */
export const reducers: ActionReducerMap<State> = {
  login: fromLogin.reducer,
  signup: fromSignup.reducer,
  file: fromFile.reducer,
  hybrid: fromHybrid.reducer,
  doctor: fromDoctor.reducer,
  error: fromError.reducer,
  pharma: fromPharma.reducer,
};

/**
 * Meta reducer to reset the state of the store.
 */
export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [resetState] : [resetState];

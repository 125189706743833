<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{checkForServierProcess() ? ('COURSE' | translate) :
    ('RELEVANT_LAB_TEST_DATA' | translate)}}</h5>
  <form [formGroup]="relevantLabTestForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{checkForServierProcess() ? ("ENCLOSE_RELEVANT_FINDINGS" | translate) :
              ("MENTIONS_LAB_TEST_DATA_RESULTS_NORMAL_RANGE"
              | translate)}}
              <span class="d-none text-danger">*</span>
            </span>
            <span class="fnt-size12px">
              {{!form.possibleRisk.value.length ? 0 :
              form.possibleRisk.value.length}}/500
            </span>
          </label>
          <div class="position-relative">
            <textarea formControlName="possibleRisk" type="text" class="form-control fnt-size14px"
              placeholder="{{checkForServierProcess() 
                ? ('ENCLOSE_RELEVANT_FINDINGS' | translate) 
                : ('MENTIONS_LAB_TEST_DATA_RESULTS_NORMAL_RANGE_PLACEHOLDER' | translate)}}"
              [maxlength]="500">
            </textarea>
          </div>
          <div *ngIf="form.possibleRisk.touched && form.possibleRisk.invalid"
            class="d-none text-danger fnt-size12px">
            {{checkForServierProcess() 
                ? ('ENCLOSE_RELEVANT_FINDINGS' | translate) 
                : ('MENTIONS_LAB_TEST_DATA_RESULTS_NORMAL_RANGE_PLACEHOLDER' | translate)}}
                 {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>

     
      <div class="col-md-12 mt-2" *ngIf="checkForNovartis()">
        <h5 class="fnt-size18px text-heading-grey mb-3">Relevant Files</h5>
        <label class="text-abbey-grey">Upload all the relevant files to support the AE Report
          </label>
        <button  [disabled]="disabled" (click)="uploadMultipleFile.click()"  [ngClass]="{'btn-success': !disabled}" class="btn btn-block fnt-size12px fnt_Medium p-1" type="button">
          <div class="d-flex justify-content-center align-items-end">
            Upload Supporting Files
          </div>
        </button>
        <input type="file" class="d-none" (click)="uploadMultipleFile.value = null" (change)="onSelectMultipleFile($event, 'relevantFiles')" #uploadMultipleFile accept=".png, .jpeg, .jpg,.pdf, .xls, .xlsx" multiple="true">
        <div class="position-relative" [hidden]="this.prescriptionEditCard?.length === 0">
          <div class="row my-2">
            <div *ngFor="let prescrptionImage of this.prescriptionEditCard; index as i" class="col-3 position-relative primeGallary text-center">
                <img *ngIf="!disabled" class="cross-icon set-cross-position" src="assets/images/cross-icon.svg" (click)="removeFile($event, 'relevantFiles', i)" alt="cross icon">
                <ng-container *ngIf="prescrptionImage.type === 'IMAGE'">
                  <dngx-full-view-galaria [images]="[prescrptionImage]" [circular]="false" [close]="true" [previewCloseOnClick]="true" [fullScreen]="true" [showItemNavigators]="false" [showThumbnails]="false" [showZoomIcon]="false" zoomIcon="assets/images/zoom-icon-white.svg"
                      maskClass="bg-black-50">
                  </dngx-full-view-galaria>
                </ng-container>
                <ng-container *ngIf="prescrptionImage.type === 'DOCUMENT'">
                  <a target="_blank" [href]="prescrptionImage.source" [download]="prescrptionImage.source">
                    <img width="100" height="100" src="assets/images/pdf.svg" alt="relevant file" />
                  </a>
                </ng-container>
            </div>
        </div>
        </div>
      </div>
      <div *ngIf="fileErrorMessage" class="ml-2 error-message text-danger">
        {{ fileErrorMessage }}
      </div>
    </div>
  </form>
</div>

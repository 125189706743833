import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl } from '@angular/forms';

/**
 * Angular imports.
 */
import { Component, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

/**
 * Store imports.
 */
import { Store } from '@ngrx/store';
import * as HomeAction from '../../../featureModules/dashboard/featureModules/home/store/actions/home.actions';
import * as HomeSelector from '../../../featureModules/dashboard/featureModules/home/store/selectors/home.selectors';
import * as LoginSelector from '../../../store/selectors/login/login.selectors';

/**
 * Constant imports.
 */
import { AE_FORM_TYPES, APPROVAL_LIST, COUNTRIES_LIST, TH_SERVIER_PROCESS, USER_TYPE } from '../../../constant/app.constant';
import { UserDetails } from '../../../featureModules/dashboard/featureModules/home/models/prescription-detail';
import { PharmaNameService } from '../../../services/pharma/pharma-name.service';

/**
 * This is used to handle the all ae reporting forms.
 */
@Component({
  selector: 'pap-ae-report',
  templateUrl: './ae-report.component.html',
  styleUrls: ['./ae-report.component.scss']
})
export class AeReportComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   *
   */
  @ViewChildren('childForms') childForms: QueryList<{ form: any; formValidity: boolean; formValues: any }>;

  /**
   *
   */
  public isAeFormsVisible = false;
  public formsToShow: { [name: string]: string } = {};
  public arFormTypes = AE_FORM_TYPES;
  public isSuccessModal = false;
  public pharmaCode: string;
  public pageFunctionality: string;
  public activatedRouteDataSub: Subscription;
  public aeReport: any;
  public aeRemarks: any;
  public ackNumber: string;
  public aeStatus = APPROVAL_LIST;
  public aeCurrentStatus !: string;
  public aeFormData: any;
  /**
   * Hold user role.
   */
  public userRole: string;
  /**
   * used to hold user type.
   */
  public userType = USER_TYPE;
  /**
   * Flag for user action on ae report send for review.
   */
  public userActionForSendForReview: boolean;
  /**
   * Flag for user action on ae report rejection.
   */
  public userActionForRejection: boolean;
  /**
   * Flag for user action on ae report rejection.
   */
  public userActionForApproval: boolean;
  /**
   * Storing rejection remarks.
   */
  public rejectionRemarks: string;
  /**
   * Storing sendForReview remarks.
   */
  public sendForReviewRemarks: string;
  /**
   * used to hold the change status loader.
   */
  public changeStatusLoader = false;
  /**
   * subscribers.
   */
  activatedRouteSub: Subscription;
  private subscriptions: Subscription = new Subscription();
  private pharmaNameChangeSub: Subscription;
  public process = '';
  public offset = 1;
  /**
   * disable the field of patient info form based on user details from user info form
   */
  disableField = {existingUser: false, createPatient: false, patientId: 0};

  /**
   * Necessary instances.
   */
  constructor(
     private store: Store,
     private router: Router,
     private activatedRoute: ActivatedRoute,
     public pharmaNameChange: PharmaNameService,
  ) {
    this.store.dispatch(HomeAction.resetAeSubmitRes());
    this.store.dispatch(HomeAction.resetAeFormList());
    this.store.dispatch(HomeAction.resetAeReport());
  }

  /**
   * after view init
   */
  ngAfterViewInit(): void {
    /**
     * reset the forms after pharma name changes
     */
    this.pharmaNameChangeSub = this.pharmaNameChange.data$.subscribe(res => {
      if (res != null) {
        this.childForms.toArray().forEach(child => {
          const formControls: { [key: string]: AbstractControl } = child.form;
          Object.keys(formControls).forEach(controlName => {
            if (controlName) {
              formControls[controlName].setValue('');
            }
          });
        });
        this.store.dispatch(HomeAction.setPharmaDetails({ pharmaDetailList: null }));
        this.store.dispatch(HomeAction.getPharmaDetails({ pharmaCode: res }));
        this.pharmaDetailSubscription();
      }
    });
    this.pharmaNameChangeSub.unsubscribe();

  }

  ngOnInit(): void {
    /**
     * to get the current user role
     * from url
     */
    const urlSegmentArr = this.router.url.split('/');
    this.process = sessionStorage.getItem('process');
    this.pharmaNameChange.setProgramPage(true);
    /**
     * set the current user role based on login creds
     */
    this.subscriptions.add(this.store.select(LoginSelector.getUserCredential).subscribe(data => {
      if (data) {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(data.accessToken);
        this.userRole = decodedToken.role;
      }
    }));
    this.pageFunctionality = urlSegmentArr[urlSegmentArr.length - 1];
    if (this.pageFunctionality !== 'create' ){
      this.activatedRouteDataSub = this.activatedRoute.params.subscribe(data => {
        this.getAeReport(data.id);
      });
    }
    this.store.dispatch(HomeAction.getAeForms({ pharmaId: localStorage.getItem('pharmaCode') , process: this.process}));
    this.subscriptions.add(this.store.select(HomeSelector.getAeSubmitRes)
      .subscribe(res => {
        if (res) {
          this.changeStatusLoader = false;
          if (res === APPROVAL_LIST.RE_REVIEW || res === APPROVAL_LIST.REJECTED) {
            this.userActionForRejection = false;
            this.userActionForSendForReview = false;
            this.resetAeForm();
          } else if (res === APPROVAL_LIST.APPROVED) {
            this.userActionForApproval = true;
          }
          this.isSuccessModal = true;
        }
      }));
    this.subscriptions.add(this.store.select(HomeSelector.getAeFormList)
      .subscribe(list => {
        if (list && list.length) {
          this.formsToShow = {};
          for (const form of list) {
            this.formsToShow[form] = form;
          }
          this.isAeFormsVisible = true;
        }
      }));
    if (localStorage.getItem('pharmaCode')) {
      this.pharmaCode = localStorage.getItem('pharmaCode');
    }

  }

/**
 * Subscribe for Pharma Details
 */
  private pharmaDetailSubscription(): void
  {
    this.subscriptions.add(this.store.select(HomeSelector.getPharmDetails)
    .subscribe(res => {
      if (res) {

        sessionStorage.removeItem('process');
        if (res.process === '' && this.checkForThailandCountry()) {
          res = { ...res, process: 'TH_NOV_R_PD_1' };
        }

        sessionStorage.setItem('process', res.process);
        this.process = res?.process;
        this.store.dispatch(HomeAction.getAeForms({ pharmaId: res.code, process: sessionStorage.getItem('process') }));
      }
    }));
  }

  /**
   *
   */
  updateFormValidity(): boolean {
    return this.childForms?.toArray()?.every(formDirective => formDirective?.formValidity);
  }

  /**
   * get ae report data
   */
  getAeReport(patientId: string): void {
    this.store.dispatch(HomeAction.getAeReport({ id: patientId }));
    this.subscriptions.add(this.store.select(HomeSelector.getAeReport).subscribe(ae => {
      this.aeReport = ae?.reportData;
      this.aeRemarks = ae?.remarks;
      this.ackNumber = ae?.ackNumber;
      this.aeCurrentStatus = ae?.status;
      this.aeFormData = ae;
    }));
  }
  /**
   * Check if country is thailand.
   */
  checkForThailandCountry(): boolean {
    return localStorage.getItem('country') === COUNTRIES_LIST.THAILAND.value;
  }

  /**
   *
   */
  aeReportSubmit(): void {
    if (this.updateFormValidity()) {
      let aeForms: any = {};
      this.childForms?.toArray().forEach(ele => {
        this.getDateTimeStamps(Object.values(ele.formValues)[0]);
        aeForms[Object.keys(ele.formValues)[0]] = Object.values(ele.formValues)[0];
        if (Object.keys(ele.formValues)[0] === 'patientGender'){
          aeForms[Object.keys(ele.formValues)[0]] = 'NA';
        }
      });
      if (aeForms?.patientForm?.height === '' || aeForms?.patientForm?.height == null) {
        aeForms.patientForm.heightUnit = '';
      }
      if (aeForms?.patientForm?.weight === '' || aeForms?.patientForm?.weight == null) {
        aeForms.patientForm.weightUnit = '';
      }
      if (
        aeForms?.suspectMedInfoForm &&
        (aeForms.suspectMedInfoForm.drugStrength === '' ||
         aeForms.suspectMedInfoForm.drugStrength == null) &&
        aeForms.suspectMedInfoForm.drugStrengthUnit !== undefined
      ) {
        aeForms.suspectMedInfoForm.drugStrengthUnit = '';
      }
      if (
        aeForms?.concomitantMedInfoForm &&
        (aeForms.concomitantMedInfoForm.drugStrength === '' ||
         aeForms.concomitantMedInfoForm.drugStrength == null) &&
        aeForms.concomitantMedInfoForm.drugStrengthUnit !== undefined
      ) {
        aeForms.concomitantMedInfoForm.drugStrengthUnit = '';
      }
      if (
        aeForms?.othersSuspectMedInfoForm &&
        (aeForms.othersSuspectMedInfoForm.drugStrength === '' ||
         aeForms.othersSuspectMedInfoForm.drugStrength == null) &&
        aeForms.othersSuspectMedInfoForm.drugStrengthUnit !== undefined
      ) {
        aeForms.othersSuspectMedInfoForm.drugStrengthUnit = '';
      }
      /**
       * manually set dob to string type
       */
      if (aeForms?.patientForm?.dateOfBirth) {
        aeForms.patientForm.dateOfBirth = this.formatDate(aeForms.patientForm.dateOfBirth);
      }

      if (this.disableField.createPatient){
        const mergedData = { ...aeForms, process: sessionStorage.getItem('process'), createPatient: true };
        aeForms = mergedData;
      } else {
        const mergedData = { ...aeForms, process: sessionStorage.getItem('process') };
        aeForms = mergedData;
      }
      this.changeStatusLoader = true;
      if (this.pageFunctionality === 'edit') {
        this.store.dispatch(HomeAction.updateAeReport(
          { id: this.aeFormData?.id, form: aeForms, pharmaCode: this.pharmaCode }
        ));
      } else {
        this.store.dispatch(HomeAction.submitAeReport({ form: aeForms, pharmaCode: this.pharmaCode }));
      }
    }
  }

  /**
   * helper function to format date string
   * @param inputDate input date in timestamp format
   * @returns date in form of 04 Jan 2024
   */
  formatDate(inputDate: string): string {
    const date = new Date(inputDate);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-GB', options);
  }

  searchPatient(_id: string): void {
    this.store.dispatch(HomeAction.getSearchedPatientId({
      id: _id,
      pharmaCode: this.pharmaCode
    }));
  }
  updateStatus(event: string, remarks: string): void {
    this.changeStatusLoader = true;
    this.store.dispatch(HomeAction.updateAeReportStatus({
      id: this.aeFormData?.id,
      status: event,
      reason: remarks.trim()
    }));
  }

  setAeReportData(userData: UserDetails): void {
     if (!userData.createNewPatient && userData.patientId !== 0){
      this.searchPatient(userData.patientId.toString());
      this.disableField = {existingUser: true, createPatient: false, patientId: userData.patientId};
     }else if (userData.createNewPatient){
      this.disableField = {existingUser: false, createPatient: true, patientId: 0};
     }
    }

  getDateTimeStamps(form): void {
    if (form.dateOfBirth) {
      form.dateOfBirth = this.formatDateToDDMMYYYY(new Date(form.dateOfBirth));
    }
    if (form.symptomEndDate) {
      form.symptomEndDate = this.formatDateToDDMMYYYY(new Date(form.symptomEndDate));
    }
    if (form.symptomOnsetDate) {
      form.symptomOnsetDate = this.formatDateToDDMMYYYY(new Date(form.symptomOnsetDate));
    }
    if (form.treatmentEndDate) {
      form.treatmentEndDate = this.formatDateToDDMMYYYY(new Date(form.treatmentEndDate));
    }
    if (form.treatmentStartDate) {
      form.treatmentStartDate = this.formatDateToDDMMYYYY(new Date(form.treatmentStartDate));
    }
    if (form.deviceUseStartDate) {
      form.deviceUseStartDate = this.formatDateToDDMMYYYY(new Date(form.deviceUseStartDate));
    }
    if (form.deviceUseStopDate) {
      form.deviceUseStopDate = this.formatDateToDDMMYYYY(new Date(form.deviceUseStopDate));
    }
    if (form.dateReceivedInfo) {
      form.dateReceivedInfo = this.formatDateToDDMMYYYY(new Date(form.dateReceivedInfo));
    }
    if (form.lastMenstrualPeriod) {
      form.lastMenstrualPeriod = this.formatDateToDDMMYYYY(new Date(form.lastMenstrualPeriod));
    }
    if (form.expectedDeliveryDate) {
      form.expectedDeliveryDate = this.formatDateToDDMMYYYY(new Date(form.expectedDeliveryDate));
    }
    if (form.dateReportToPs) {
      form.dateReportToPs = this.formatDateToDDMMYYYY(new Date(form.dateReportToPs));
    }
    if (form.dateOfDeath) {
      form.dateOfDeath = this.formatDateToDDMMYYYY(new Date(form.dateOfDeath));
    }
  }

  formatDateToDDMMYYYY(date): string {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const parts = formattedDate.split(' ');
    const day = parts[1].split(',')[0];
    if (parts.length === 3) {
      return `${day} ${parts[0]} ${parts[2]}`;
    }
    return formattedDate;
  }

  resetAeForm(): void {
    this.isSuccessModal = false;
    if (!this.userRole || this.userRole === USER_TYPE.APPROVER) {
      this.router.navigate(['/approval/aeReport/list']);
    } else if (this.userRole === USER_TYPE.PAP_SUPERVISOR) {
      this.router.navigate(['/supervisor/aeReport/list']);
    }
  }

  ngOnDestroy(): void {
    this.pharmaNameChange.setProgramPage(false);
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.pharmaNameChangeSub){
      this.pharmaNameChangeSub.unsubscribe();
    }
    if (sessionStorage.getItem('process')) {
      sessionStorage.removeItem('process');
    }
  }
  /**
   * Check if process is for servier chatbot flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
  checkForSingaporeCountry(): boolean {
    return  localStorage.getItem('country') === COUNTRIES_LIST.SINGAPORE.value;
  }
}

/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COUNTRIES_LIST, SG_BMS_NR_PND_1, TH_SERVIER_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';
import aeReportValidation from '../../../.../../../../../assets/static/ae-report-validation.json';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-agent-info-form',
  templateUrl: './agent-info-form.component.html',
  styleUrls: ['./agent-info-form.component.scss']
})
export class AgentInfoFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  /**
   * input current process
   */
  @Input() process: string;
  /**
   * Form pediatric report.
   */
  public agentInfoForm: FormGroup;
  private disabled: boolean;
  public countryList = [{ id: 1, name: 'Docquity' }];

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.agentInfoForm.controls; }
  get formValidity(): boolean { return this.agentInfoForm.valid; }
  get formValues(): { agentInfoForm: any; } { return { agentInfoForm: this.agentInfoForm.value }; }
  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled
    = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }
  /**
   * function to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.agentInfoForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }
  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.agentInfoForm = this.fb.group({
      agentName: new FormControl(
        {value : this.formData?.agentName ? this.formData?.agentName : '', disabled: this.disabled},
        !this.checkForSingaporeCountry() ? [Validators.required, Validators.maxLength(100)] : []
      ),
      dateReceivedInfo: new FormControl(
        {value : this.formData?.dateReceivedInfo ? this.formData?.dateReceivedInfo : '',
         disabled: this.disabled},
        Validators.required),
      agentEmail: new FormControl(
      {value : this.formData?.agentEmail ? this.formData?.agentEmail : '', disabled: this.disabled},
      ),
      agentProgramVendor: new FormControl(
        {value : this.formData?.agentProgramVendor
               ? this.formData?.agentProgramVendor : 'Docquity', disabled: this.disabled},
        ),
    });
  }

  /**
   * Check if process is for servier flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
  checkForSingaporeCountry(): boolean {
    return  localStorage.getItem('country') === COUNTRIES_LIST.SINGAPORE.value;
  }
  checkIfFieldIsMandatory(fieldName: string): boolean {
    if (this.process === SG_BMS_NR_PND_1) {
      return aeReportValidation[this.process]?.agentInfoForm[fieldName]?.mandatory;
    }
    return true;
  }
}

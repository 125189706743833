<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'CONCOMITANT_MED_INFO' | translate}}</h5>
  <form [formGroup]="concomitantMedInfoForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{(checkForServierProcess() ? "MEDIC_LIST" :"DRUG_NAME") | translate}}
            </span>
          </label>
          <p-autoComplete [styleClass]="'w-100'" [inputId]="'inputdrug'"
            [panelStyleClass]="'bg-white border rounded p-2 fnt-size14px'" [inputStyleClass]="'form-control fnt-size14px'"
            [suggestions]="drugSearchArray" [autoHighlight]="true" (completeMethod)="searchDrug($event)"
            (onSelect)="selectedDrug($event)" placeholder="{{'SEARCH_DRUG' | translate}}" [(ngModel)]="selectedDrugValue"
            formControlName="drugName" [maxlength]="1000">
            <ng-template let-drug pTemplate="item">
              <div class="mb-1 px-2 fnt-size12px mb-2" [innerHTML]="drug.genericName +' ('+ drug.name+')' +
                                  drug.strength.value + drug.strength.unit.name">
              </div>
            </ng-template>
          </p-autoComplete>
          <div *ngIf="form.drugName.touched && form.drugName.invalid" class="text-danger fnt-size12px">
            {{"DRUG_NAME" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
          <div *ngIf="invalidDrug" class="text-danger fnt-size12px">
            No result found
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="" class="fnt-size14px">{{(checkForSingaporeCountry() ? "DRUG_UNIT" :"DRUGS_STRENGTH") | translate}}
            <span class="d-none text-danger">*</span>
          </label>
          <div class="row no-gutters border rounded">
            <div class="col-8 border-right">
              <input type="text" pattern="^[0-9]+$" maxlength="10" class="form-control fnt-size14px border-0" name="drugStrength"
                formControlName="drugStrength" placeholder="{{'ENTER_STRENGTH' | translate}}" dngxDigitOnly/>
            </div>
            <div class="col-4">
              <select class="form-control rounded-left-none fnt-size14px border-0 text-capitalize"
                formControlName="drugStrengthUnit">
                <option *ngFor="let dsu of drugStrengthUnitList" [value]="dsu.name"
                  [selected]="dsu.name === form.drugStrengthUnit?.value" class="mt-5">
                  {{dsu.name}}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="form.drugStrength.touched && form.drugStrength.invalid" class="text-danger fnt-size12px">
            {{"ONLY_NUMBERS_ALLOWED" | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"ADMINISTRATION_ROUTE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="administrationRoute" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_ADMINISTRATION_ROUTE' | translate}}" [maxlength]="1000" />
          </div>
          <div *ngIf="form.administrationRoute.touched && form.administrationRoute.invalid"
            class="d-none text-danger fnt-size12px">
            {{"ADMINISTRATION_ROUTE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>

      <div class="col-md-4" *ngIf="checkForSingaporeCountry() || checkForThailandCountry()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span *ngIf="checkForSingaporeCountry()">
              {{"DOSE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
            <span *ngIf="checkForThailandCountry()">
              {{(checkForServierProcess() ? "DOSING_DOSAGE_APPLICATION" :"DOSING_REGIMEN_DAILY_DOSING") | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
              <input formControlName="dailyDosing" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_DAILY_DOSING' | translate}}" [maxlength]="checkForSingaporeCountry() ? 500 : 50" /> 
          </div>
          <div *ngIf="form?.dailyDosing?.touched && form?.dailyDosing?.invalid" class="d-none text-danger fnt-size12px">
            {{"FORMULATION" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess() && !checkForSingaporeCountry()" >
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"FORMULATION" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="formulation" type="text" class="form-control fnt-size14px"
            [value]="selectedDrugData ? selectedDrugData.dosageForm: ''"
              placeholder="{{'ENTER_FORMULATION' | translate}}" [maxlength]="50" />
          </div>
          <div *ngIf="form.formulation.touched && form.formulation.invalid" class="d-none text-danger fnt-size12px">
            {{"FORMULATION" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"FREQUENCY" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="frequency" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_FREQUENCY' | translate}}" [maxlength]=" checkForSingaporeCountry() ? 200 : 20" />
          </div>
          <div *ngIf="form.frequency.touched && form.frequency.invalid" class="d-none text-danger fnt-size12px">
            {{"FREQUENCY" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size14px"*ngIf="!checkForSingaporeCountry()">
            {{(checkForServierProcess() ? "ADMINISTERED_FROM" :"TREATMENT_START_DATE") | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <label class="fnt-size14px" *ngIf="checkForSingaporeCountry()">
            {{'THERAPY_START_DATE'|translate}}
           
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.treatmentStartDate.touched && form.treatmentStartDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="treatmentStartDate" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.treatmentStartDate.touched ) && form.treatmentStartDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{'TREATMENT_START_DATE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size14px" *ngIf="!checkForSingaporeCountry()">
            {{'TREATMENT_END_DATE' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <label class="fnt-size14px" *ngIf="checkForSingaporeCountry()" >
            {{'THERAPY_END_DATE' | translate}}
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.treatmentEndDate.touched && form.treatmentEndDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="treatmentEndDate" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.treatmentEndDate.touched ) && form.treatmentEndDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{'TREATMENT_END_DATE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"INDICATION_FOR_USE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="indicationForUse" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_INDICATION' | translate}}" [maxlength]="getMaxLength()" />
          </div>
          <div *ngIf="form.indicationForUse.touched && form.indicationForUse.invalid"
            class="d-none text-danger fnt-size12px">
            {{"INDICATION_FOR_USE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForSingaporeCountry()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"BATCH_NO_EXPIRY_DATE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="batchNoAndExpiryDate" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_HERE' | translate}}" [maxlength]="checkForNovartis() ? 500 : 50" />
          </div>
          <div *ngIf="form.batchNoAndExpiryDate.touched && form.batchNoAndExpiryDate.invalid"
            class="d-none text-danger fnt-size12px">
            {{"BATCH_NO_EXPIRY_DATE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

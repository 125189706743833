/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { COUNTRIES_LIST, NOVARTIS_PROCESS, SG_BMS_NR_PND_1, TH_SERVIER_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';
import { DrugSearchListConvertedData, DrugSelectedData, SelectedDrugStrength } from 'projects/nga-PAP/src/app/featureModules/dashboard/featureModules/home/models/prescription-detail';
import { DrugSearchListData } from 'projects/nga-PAP/src/app/models/responses.model';
import { Subscription } from 'rxjs';
import * as HomeAction from 'projects/nga-PAP/src/app/featureModules/dashboard/featureModules/home/store/actions/home.actions';
import * as HomeSelector from 'projects/nga-PAP/src/app/featureModules/dashboard/featureModules/home/store/selectors/home.selectors';
import aeReportValidation from '../../../.../../../../../assets/static/ae-report-validation.json';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-suspect-med-info-form',
  templateUrl: './suspect-med-info-form.component.html',
  styleUrls: ['./suspect-med-info-form.component.scss']
})
export class SuspectMedInfoFormComponent implements OnInit, OnChanges , OnDestroy {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;

  /**
   * Form pediatric report.
   */
  public suspectMedInfoForm: FormGroup;
  private disabled: boolean;
  public drugSearchArray: DrugSearchListConvertedData[] = [];
  /**
   * Variable to select search string.
   */
  public toHighlight: string;
  /**
   * used to subscribe the drug search data.
   */
  public drugSearchSub: Subscription;
  /**
   *  used to show the drug search result.
   */
   public drugSearchResults: DrugSearchListData[];
  /**
   *  used to create selected drug strength json string to array object.
   */
  public selectedDrugStrengthObj: Array<SelectedDrugStrength>;
  /**
   * used to store the selected drug data
   */
   public selectedDrugData: DrugSelectedData;
  /**
   * used to set selected drug in a format.
   */
  public selectedDrugValue: any;
  public invalidDrug = false;

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.suspectMedInfoForm.controls; }
  get formValidity(): boolean { return this.suspectMedInfoForm.valid; }
  get formValues(): { suspectMedInfoForm: any; } { return { suspectMedInfoForm: this.suspectMedInfoForm.value }; }

  /**
   * Drug strength list.
   */
  drugStrengthUnitList = [
    { id: 1, name: 'mg' },
    { id: 2, name: 'ug' },
    { id: 3, name: 'g' },
    { id: 4, name: 'mg/mL' },
    { id: 5, name: 'IU/mL' },
    { id: 6, name: '%' }
  ];
  administrationRouteList = [
    { id: 1, name: 'Oral' },
    { id: 2, name: 'Intravenous, IV' },
    { id: 3, name: 'Intramuscular, IM' },
    { id: 4, name: 'Subcutaneous, SC' },
    { id: 5, name: 'Intraocular' },
    { id: 6, name: 'NA' }
  ];
  causality = [
    { id: 1, name: 'Related' },
    { id: 2, name: 'Non-related' }
  ];
  actionTaken = [
    { id: 1, name: 'None' },
    { id: 2, name: 'Interrupted' },
    { id: 1, name: 'Discontinued' },
    { id: 2, name: 'Dose-Increased' },
    { id: 2, name: 'Dose Reduced' },
    { id: 1, name: 'IV Rate Reduced' },
    { id: 2, name: 'Unknown' }
  ];

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder, private store: Store, ) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }
  /**
   * function to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.suspectMedInfoForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }
  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.suspectMedInfoForm = this.fb.group({
      drugName: new FormControl(
        {value : this.formData?.drugName ? this.formData?.drugName : '', disabled: this.disabled},
       [Validators.required, Validators.maxLength(100)]),
      drugStrength: new FormControl(
        {value : this.formData?.drugStrength ? this.formData?.drugStrength : '', disabled: this.disabled}),
      drugStrengthUnit: new FormControl(
        {value : this.formData?.drugStrengthUnit ? this.formData?.drugStrengthUnit : 'mg', disabled: this.disabled}),
      administrationRoute: new FormControl(
        {value : this.formData?.administrationRoute ? this.formData?.administrationRoute : '', disabled: this.disabled},
       Validators.maxLength(100)),
      dailyDosing: new FormControl(
        {value : this.formData?.dailyDosing ? this.formData?.dailyDosing : '', disabled: this.disabled}),
       drugFrequency: new FormControl(
        {value : this.formData?.drugFrequency ? this.formData?.drugFrequency : '', disabled: this.disabled},
       Validators.maxLength(200)),
      treatmentStartDate: new FormControl(
        {value : this.formData?.treatmentStartDate ? this.formData?.treatmentStartDate : '', disabled: this.disabled}),
      treatmentEndDate: new FormControl(
        {value : this.formData?.treatmentEndDate ? this.formData?.treatmentEndDate : '', disabled: this.disabled}),
      indicationForUse: new FormControl(
        {value : this.formData?.indicationForUse ? this.formData?.indicationForUse : '', disabled: this.disabled},
       Validators.maxLength(500)),
      batchNoAndExpiryDate: new FormControl(
        {value : this.formData?.batchNoAndExpiryDate ? this.formData?.batchNoAndExpiryDate : '',
         disabled: this.disabled},
       Validators.maxLength(500)),
      abateAfterStopping: new FormControl(
        {value : this.formData?.abateAfterStopping ? this.formData?.abateAfterStopping : '', disabled: this.disabled}),
      recurOnReadMinistration: new FormControl(
        {value : this.formData?.recurOnReadMinistration ? this.formData?.recurOnReadMinistration : '',
         disabled: this.disabled}),
      formulation: new FormControl(
        {value : this.formData?.formulation ? this.formData?.formulation : '', disabled: this.disabled},
       Validators.maxLength(50)),
      causality: new FormControl(
        {value : this.formData?.causality ? this.formData?.causality : '', disabled: this.disabled},
       Validators.maxLength(50)),
       actionTaken: new FormControl(
        {value : this.formData?.actionTaken ? this.formData?.actionTaken : '',
         disabled: this.disabled}),
    });
    if (this.formData?.drugName) {
      this.selectedDrugValue = this.formData?.drugName;
    }
    if (this.checkForServierProcess())
      {
        this.suspectMedInfoForm.removeControl('formulation');
      }
    if (this.checkForSingaporeCountry()){
        this.suspectMedInfoForm.get('indicationForUse').setValidators(Validators.required);
        this.suspectMedInfoForm.get('indicationForUse').updateValueAndValidity();
        this.suspectMedInfoForm.get('dailyDosing').setValidators(Validators.required);
        this.suspectMedInfoForm.get('dailyDosing').updateValueAndValidity();
        this.suspectMedInfoForm.get('batchNoAndExpiryDate').setValidators(Validators.required);
        this.suspectMedInfoForm.get('batchNoAndExpiryDate').updateValueAndValidity();
      }
  }
  /**
   * Check if process is for servier chatbot flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }

  checkForSingaporeCountry(): boolean {
    return  localStorage.getItem('country') === COUNTRIES_LIST.SINGAPORE.value;
  }
  checkForThailandCountry(): boolean {
    return  localStorage.getItem('country') === COUNTRIES_LIST.THAILAND.value;
  }
  checkForNovartis(): boolean {
    return this.process === NOVARTIS_PROCESS;
  }

  /**
   * clear the selected value for given form field if
   * already selected
   * @param formField input form field
   * @param value input value of the option
   */
  clearSelection(formField: string, value: string): void {
    const currentValue = this.suspectMedInfoForm.get(formField).value;
    if (currentValue === value) {
      this.suspectMedInfoForm.get(formField).setValue('');
    }
  }
 /**
  *  search drug
  */
  searchDrug(event): void {
    this.toHighlight = event.query;
    this.store.dispatch(HomeAction.resetDrugList());
    this.store.dispatch(HomeAction.loadDrugList(
      {
        keyword: event.query
      }
    ));

    this.drugSearchSub = this.store.select(HomeSelector.getSearchDrugList).subscribe(resData => {
      if (resData !== null) {
        this.invalidDrug = false;
        this.drugSearchResults = resData.list;
        this.drugSearchArray = [];
        if (this.drugSearchResults !== null) {
          this.drugSearchResults.forEach(element => {
            this.selectedDrugStrengthObj = JSON.parse(element.strength);
            this.selectedDrugStrengthObj.forEach(strength => {
              this.drugSearchArray.push({
                id: element.id,
                name: element.brandName,
                genericName: element.genericName,
                strength: {
                  unit: {
                    id: 1,
                    name: strength.Unit
                  },
                  value: strength.Value
                },
                dosageForm: element.dosageForm,
                routeOfAdministration: element.route
              });
            });
          });
        }
      }
      if (this.drugSearchResults == null){
        this.invalidDrug = true;
     }
    });
  }

  selectedDrug(event): void {
    console.log('value of event:', event);
    this.selectedDrugData = event;
    this.selectedDrugValue = event.genericName + ' (' + event.name + ')';
    this.suspectMedInfoForm.get('drugStrength').setValue(event.strength.value);
    this.suspectMedInfoForm.get('administrationRoute').setValue(event.routeOfAdministration);
    this.suspectMedInfoForm.get('formulation').setValue(event.dosageForm);
  }

  checkIfFieldIsMandatory(fieldName: string): boolean {
    if (this.process === SG_BMS_NR_PND_1) {
      return aeReportValidation[this.process]?.suspectMedInfoForm[fieldName]?.mandatory;
    }
    return true;
  }
  ngOnDestroy(): void {
    if (this.drugSearchSub) {
      this.drugSearchSub.unsubscribe();
    }
  }
  getFieldName(fieldName: string): string {
    return aeReportValidation[this.process]?.suspectMedInfoForm[fieldName]?.title;
  }
  getFieldPlaceholder(fieldName: string): string {
    return aeReportValidation[this.process]?.suspectMedInfoForm[fieldName]?.placeholder;
  }

}

<!-- {{form.value | json}} -->
<div *ngIf="responseData">
    <div class="d-flex flex-row align-items-start mt-3 ml-2">
        <img class="mr-2 docquityLogo" [src]="logo" alt="docquity logo">
        <form class="w-100" [formGroup]="form" (ngSubmit)="submitApplicationForm(responseData.id, responseData.index)" autocomplete="off" #myForm="ngForm">
            <div class="bg-white p-2 w-75">
                <div class="text-abbey-grey fnt-size12px fnt_Medium" [innerHtml]="responseData.formType === 'PROGRAM_INFO' && checkForOazisProcess() ? this.translate.instant('FD_TITLE_PROGRAM_NAME') : responseData.formType === 'PATIENT_VERIFY_OTP' ? responseData.title.replace('PHONE_NUMBER', '+' + patientCountryCode + '-' + helpers.hideCharacters('' + patientPhoneNo, this.fieldType.PHONE)) : responseData.title"></div>
                <div *ngFor="let field of responseData.attributes; index as i">
                    <div *ngIf="field.type === this.fieldType.TEXT && !(field.fieldName === 'referenceID' && checkForServierProcess()) && !(field.fieldName === 'soldToPatientHn' && !checkForServierProcess())" class="form-group"  [hidden]="field.fieldName === 'hiddenResendOtp'" [ngClass]="{'mt-2': i === 0}">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
              <span *ngIf=" field.fieldName === doctorName">
                {{ "DOCTOR_TITLE" | translate }}
              </span>
              <span *ngIf=" field.fieldName !== doctorName">
                {{field.title}}
              </span>
              <span *ngIf="field.fieldName !== 'middleName' && field.fieldName !== 'referenceID' && field.fieldName !== 'nhsoProofID' && !(checkForSingaporeCountry() && field.fieldName === 'patientInitials') && ((field.fieldName !== 'caregiverName') || (checkForOazisProcess() && field.fieldName === 'caregiverName')) && field.fieldName !== 'caregiverRelation'" class="text-danger">*</span>
            </label>
                        <input (keyup)="userInput(field.fieldName, $event)" formControlName="{{field.fieldName}}" type="text" class="form-control fnt-size12px fnt_Medium bg-porcelain-white" placeholder="{{field.placeHolder}}" [ngClass]="{
                  'border border-danger': form.get(field.fieldName).touched && form.get(field.fieldName).invalid,
                  'border-0': (form.get(field.fieldName).untouched || form.get(field.fieldName).valid)
                              && (!myForm.submitted)
                }"
                [attr.maxLength]="field.fieldName === 'patientInitials' ? 10 : ((field.fieldName === 'treatingPatientID' || field.fieldName === 'shippingPatientID' || field.fieldName === 'nhsoProofID' || field.fieldName === 'interactionId' || field.fieldName === 'soldToPatientHn') ? 20 : null)">
                        <div *ngIf="field.fieldName === doctorName &&(form.get(field.fieldName).touched || myForm.submitted)
            &&
            form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                            {{ "DOCTOR_NAME_ERR" | translate }}
                        </div>
                        <div *ngIf="field.fieldName !== doctorName && (form.get(field.fieldName).touched || myForm.submitted)
              &&
              form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                            {{field.title}} {{'IS_REQUIRED' | translate}}
                        </div>
                    </div>
                    <div *ngIf="checkForThailandCountry() && field.type === this.fieldType.LOCATION" class="form-group" [ngClass]="{'mt-2': i === 0}">
                      <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
                        <span *ngIf=" field.fieldName === doctorName">
                          {{ "DOCTOR_TITLE" | translate }}
                        </span>
                        <span *ngIf=" field.fieldName !== doctorName">
                          {{field.title}}
                        </span>
                        <span class="text-danger">*</span>
                      </label>
                      <textarea (keyup)="userInput(field.fieldName, $event)" formControlName="{{field.fieldName}}" type="text" class="form-control fnt-size12px fnt_Medium bg-porcelain-white" placeholder="{{field.placeHolder}}" [ngClass]="{
                          'border border-danger': form.get(field.fieldName).touched && form.get(field.fieldName).invalid,
                          'border': (form.get(field.fieldName).untouched || form.get(field.fieldName).valid)
                                      && (!myForm.submitted)
                        }" [maxlength]="500">
                      </textarea>
                      <div *ngIf="field.fieldName === doctorName &&(form.get(field.fieldName).touched || myForm.submitted) && form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                        {{ "DOCTOR_NAME_ERR" | translate }}
                      </div>
                      <div *ngIf="field.fieldName !== doctorName && (form.get(field.fieldName).touched || myForm.submitted) && form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                          {{field.title}} {{'IS_REQUIRED' | translate}}
                      </div>
                  </div>
                    <div *ngIf="(field.type === this.fieldType.DATE && field.fieldName !== 'dateOfAdministration') || (field.fieldName === 'dateOfAdministration' && process === unEmbeddedPharma)" class="form-group mb-2 position-relative">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
              {{field.title}}
              <span class="text-danger">*</span>
            </label>
                        <div class="calendar-field w-100">
                            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="!checkForThailandCountry() ? true : false" yearRange="1922:2030" [inline]="false" [styleClass]="form.get(field.fieldName).touched && form.get(field.fieldName).invalid ? 'pcomponent-invalid' : 'border-0'"
                                [inputStyleClass]="'form-control fnt-size12px fnt_Medium bg-porcelain-white'" formControlName="{{field.fieldName}}" placeholder="{{field.placeHolder}}" dateFormat="dd M yy" [maxDate]="field.fieldName !== 'dateOfAdministration' ? currentdate : null" (onShow)="showCalendarPanel()"></p-calendar>
                        </div>
                        <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
              &&
              form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                            {{field.title}} {{'IS_REQUIRED' | translate}}
                        </div>
                    </div>
                    <div *ngIf="field.type === this.fieldType.EMAIL && !checkForOazisProcess()" class="form-group" [hidden]="application === hybridForm && responseData.formType === PatientInfoForm">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
              {{field.title}}
              <span *ngIf="!checkForSingaporeCountry()"  class="text-danger">*</span>
            </label>
                        <input formControlName="{{field.fieldName}}" type="text" class="form-control fnt-size12px fnt_Medium bg-porcelain-white border-0" placeholder="{{field.placeHolder}}" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}" (keyup)="userInput(field.fieldName, $event)" [ngClass]="{
                  'border border-danger': form.get(field.fieldName).touched && form.get(field.fieldName).invalid,
                  'border-0': (form.get(field.fieldName).untouched || form.get(field.fieldName).valid)
                              && (!myForm.submitted)
                }">
                        <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
              &&
              form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                            <div *ngIf="form.get(field.fieldName).invalid">{{'PLEASE_PROVIDE_A_VALID_EMAIL'|translate}}</div>
                        </div>
                        <div *ngIf="this.responseData.patientAlreadyExistsErr" class="text-danger fnt-size10px">
                            {{this.responseData.patientAlreadyExistsErr}}</div>
                    </div>
                    <div *ngIf="field.type === this.fieldType.PHONE" class="form-group" [hidden]="application === hybridForm && responseData.formType === PatientInfoForm && field.fieldName !=='caregiverMobile'">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
              {{field.title}}
              <span *ngIf="field.fieldName !== 'caregiverMobile'" class="text-danger">*</span>
            </label>
                        <div class="d-flex position-relative">                
                            <p-dropdown class="d-flex align-items-center position-absolute h-100 signup-phone-code pr-2" [options]="this.isPatientForm ? [this.defaultCountry] : this.countries" formControlName="countryCode" [optionValue]="'countryCode'">
                                <ng-template pTemplate="selectedItem">
                                    <div class="country-item country-item-value" *ngIf="this.form.get('countryCode')">
                                        <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                                            +{{this.form.get('countryCode').value}}
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div class="country-item d-flex justify-content-between align-items-center p-2">
                                        <div class="country-item-value d-flex">
                                            <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'" alt="country flag" />
                                            <div class="fnt-size12px text-abbey-grey mr-2" [ngClass]="{'font-weight-bold': this.form.get('countryCode').value === item.countryCode}">
                                                {{item.name}}
                                            </div>
                                            <div class="fnt-size12px text-abbey-grey" [ngClass]="{'font-weight-bold': this.form.get('countryCode').value === item.countryCode}">
                                                +{{item.countryCode}}
                                            </div>
                                        </div>
                                        <img *ngIf="this.form.get('countryCode').value === item.countryCode" src="assets/images/checkbox.svg" alt="green checked checkbox">
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <input *ngIf="field.type === 'PHONE'" tabindex="1" formControlName="{{field.fieldName}}" type="text" #phoneNumber id="phoneNumber" inputmode="numeric" class="form-control fnt-size12px fnt_Medium bg-porcelain-white border-0 signup-phone-input" placeholder="{{field.placeHolder}}"
                                (input)="validateNo($event)"  (keyup)="userInput(field.fieldName, $event); onKeyUpInCaregiverForm()"[ngClass]="{
                  'border border-danger': form.get(field.fieldName).touched && form.get(field.fieldName).invalid,
                  'border-0': (form.get(field.fieldName).untouched || form.get(field.fieldName).valid)
                              && (!myForm.submitted)
                }" dngxDigitOnly [maxlength]="checkForSingaporeCountry() ? 15 : Infinity" minlength="8" >
                        </div>
                        <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
              &&
              form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                            <div *ngIf="form.get(field.fieldName).invalid  && !checkForSingaporeCountry() ">{{"PLEASE_PROVIDE_A_VALID_NO"|translate}}</div>
                            <div *ngIf="form.get(field.fieldName).invalid && checkForSingaporeCountry() ">{{"MOBILE_INVALID_SG"|translate}}</div>
                        </div>
                    </div>
                    <div *ngIf="field.type === this.fieldType.SELECT && field.fieldName === 'gender'" class="form-group">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0"> {{field.title}}
              <span class="text-danger" *ngIf="!checkForThailandCountry() || (checkForThailandCountry() && checkForServierProcess())">*</span>
            </label>
                        <select [required]="!checkForThailandCountry() || (checkForThailandCountry() && checkForServierProcess()) ? true : false" class="form-control fnt-size12px fnt_Medium bg-porcelain-white" formControlName="{{field.fieldName}}">
              <option value='' disabled selected class="d-none">{{field.placeHolder}}</option>
              <option [value]="'MALE'" [selected]="'MALE' === this.form.get(field.fieldName).value" class="mt-5">{{
                "MALE" | translate }}</option>
              <option [value]="'FEMALE'" class="mt-5" [selected]="'FEMALE' === this.form.get(field.fieldName).value">{{
                "FEMALE" | translate }}</option>
              <option *ngIf="!checkForSingaporeCountry()" [value]="'OTHER'" class="mt-5" [selected]="'OTHER' === this.form.get(field.fieldName).value">{{
                "PREFER_NOT" | translate }}</option>
            </select>
                        <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
                &&
                form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                            {{field.title}} {{'IS_REQUIRED' | translate}}
                        </div>
                    </div>
                    <div *ngIf="checkForThailandCountry() && field.type === this.fieldType.SELECT && field.fieldName === 'securityProfile'" class="form-group">
                      <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0"> {{checkForServierProcess() ? ('HEALTHCARE_BENEFIT_SCHEME' | translate) : field.title}}
                        <span class="text-danger">*</span>
                      </label>
                      <select required class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
                        formControlName="{{field.fieldName}}">
                        <option value='' disabled selected class="d-none">{{checkForServierProcess() ? ('SELECT_SCHEME' | translate) : field.placeHolder}}</option>
                        <ng-container *ngIf="!checkForServierProcess()">
                          <option [value]="'CSMBS'" [selected]="'CSMBS' === this.form.get(field.fieldName).value" class="mt-5">{{'CSMBS'}}
                          </option>
                          <option [value]="'Non-CSMBS'" class="mt-5" [selected]="'Non-CSMBS' === this.form.get(field.fieldName).value">
                            {{'Non-CSMBS'}}</option>
                          <option [value]="'Self-pay'" class="mt-5" [selected]="'Self-pay' === this.form.get(field.fieldName).value">
                            {{'Self-pay'}}</option>
                          <option [value]="'Private insurance'" class="mt-5"
                            [selected]="'Private insurance' === this.form.get(field.fieldName).value">{{'Private insurance'}}</option>
                        </ng-container>
                        <ng-container *ngIf="checkForServierProcess()">
                          <option [value]="'Self-pay (CSMBS)'" [selected]="'Self-pay (CSMBS)' === this.form.get(field.fieldName).value" class="mt-5">{{'Self-pay (CSMBS)'}}
                          </option>
                          <option [value]="'Self-pay (UC)'" class="mt-5" [selected]="'Self-pay (UC)' === this.form.get(field.fieldName).value">
                            {{'Self-pay (UC)'}}</option>
                          <option [value]="'Self-pay (SS)'" class="mt-5" [selected]="'Self-pay (SS)' === this.form.get(field.fieldName).value">
                            {{'Self-pay (SS)'}}</option>
                          <option [value]="'Self-pay (Other)'" class="mt-5"
                            [selected]="'Self-pay (Other)' === this.form.get(field.fieldName).value">{{'Self-pay (Other)'}}</option>
                          <option [value]="'Self-pay (Private insurance)'" class="mt-5"
                            [selected]="'Self-pay (Private insurance)' === this.form.get(field.fieldName).value">{{'Self-pay (Private insurance)'}}</option>
                          <option [value]="'OCPA (CSMBS)'" class="mt-5"
                            [selected]="'OCPA (CSMBS)' === this.form.get(field.fieldName).value">{{'OCPA (CSMBS)'}}</option>
                          <option [value]="'OCPA (Other)'" class="mt-5"
                            [selected]="'OCPA (Other)' === this.form.get(field.fieldName).value">{{'OCPA (Other)'}}</option>
                        </ng-container>
                      </select>
                      <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
                                  &&
                                  form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                        {{checkForServierProcess() ? ('HEALTHCARE_BENEFIT_SCHEME' | translate) : field.title}} {{'IS_REQUIRED' | translate}}
                      </div>
                    </div>
                    <div *ngIf="(checkForThailandCountry()) && field.type === this.fieldType.SELECT && field.fieldName === 'idCardType'" class="form-group">
                      <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0"> {{field.title}}
                        <span class="text-danger">*</span>
                      </label>
                      <select required class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
                        formControlName="{{field.fieldName}}">
                        <option value='' disabled selected class="d-none">{{field.placeHolder}}</option>
                        <option [value]="'Thai ID card'" [selected]="'Thai ID card' === this.form.get(field.fieldName).value" class="mt-5">{{'Thai ID card'}}
                        </option>
                        <option [value]="'Non-Thai ID card'" class="mt-5" [selected]="'Non-Thai ID card' === this.form.get(field.fieldName).value">
                          {{'Non-Thai ID card'}}</option>
                        <option [value]="'Work permit card'" class="mt-5"
                          [selected]="'Work permit card' === this.form.get(field.fieldName).value">{{'Work permit card'}}</option>
                        <option [value]="'Passport'" class="mt-5"
                          [selected]="'Passport' === this.form.get(field.fieldName).value">{{'Passport'}}</option>
                      </select>
                      <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
                                  &&
                                  form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                        {{field.title}} {{'IS_REQUIRED' | translate}}
                      </div>
                    </div>
                    <div *ngIf="checkForSingaporeCountry() && field.type === this.fieldType.SELECT && field.fieldName === 'idCardType'" class="form-group">
                      <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0"> {{field.title}}
                        <span class="text-danger">*</span>
                      </label>
                      <select required class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
                        formControlName="{{field.fieldName}}">
                        <option value='' disabled selected class="d-none">{{field.placeHolder}}</option>
                        <option [value]="'national_id'" [selected]="'national_id' === this.form.get(field.fieldName).value" class="mt-5">{{'National ID'}}
                        </option>
                        <option [value]="'passport'" class="mt-5"
                          [selected]="'passport' === this.form.get(field.fieldName).value">{{'Passport'}}</option>
                      </select>
                      <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
                                  &&
                                  form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                        {{field.title}} {{'IS_REQUIRED' | translate}}
                      </div>
                    </div>
                    <div *ngIf="((checkForThailandCountry() || checkForSingaporeCountry()) && field.type === this.fieldType.SELECT) && (field.fieldName === 'treatmentHospital' || (field.fieldName === 'soldToHospital' && checkForServierProcess()))" class="form-group dropdown_hospital_list">
                      <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
                        {{field.title}}
                        <span class="text-danger">*</span>
                      </label>
                      <p-dropdown class="w-100 pr-2 " [options]="hospitalOptions" [formControlName]="field.fieldName" optionLabel="name"
                        [placeholder]="field.placeHolder" filterPlaceholder="Search" [filter]="true" [filterBy]="name"
                        [autoDisplayFirst]="false" panelStyleClass="bg-white border p-2 fnt-size14px" styleClass="form-control fnt-size14px">
                      </p-dropdown>
                      <div *ngIf="(form?.get(field?.fieldName)?.touched || myForm.submitted) && form?.get(field?.fieldName)?.invalid"
                        class="text-danger fnt-size10px">
                        {{field.title}} {{'IS_REQUIRED' | translate}}
                      </div>
                    </div>
                    <div *ngIf="checkForThailandCountry() && field.type === this.fieldType.SELECT && field.fieldName === 'dispensingHospital'" class="form-group dropdown_hospital_list">
                      <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
                        {{field.title}}
                        <span class="text-danger">*</span>
                      </label>
                      <p-dropdown class="w-100 pr-2 " [options]="hospitalOptions" [formControlName]="field.fieldName" optionLabel="name"
                        [placeholder]="field.placeHolder" filterPlaceholder="Search" [filter]="true" [filterBy]="name"
                        [autoDisplayFirst]="false" panelStyleClass="bg-white border p-2 fnt-size14px" (onChange)="onHospitalChange($event)"
                        styleClass="form-control fnt-size14px">
                      </p-dropdown>
                      <div *ngIf="(form?.get(field?.fieldName)?.touched || myForm.submitted) && form?.get(field?.fieldName)?.invalid"
                        class="text-danger fnt-size10px">
                        {{field.title}} {{'IS_REQUIRED' | translate}}
                      </div>
                    </div>
                    <div *ngIf="checkForThailandCountry() && field.type === this.fieldType.SELECT && field.fieldName === 'hospitalUser'" class="form-group dropdown_hospital_list">
                      <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
                        {{field.title}}
                        <span class="text-danger">*</span>
                      </label>
                      <p-dropdown class="w-100 pr-2 " [options]="userHospitalList" [formControlName]="field.fieldName" optionLabel="name"
                        [placeholder]="field.placeHolder" filterPlaceholder="Search" [filter]="true" [filterBy]="name"
                        [autoDisplayFirst]="false" panelStyleClass="bg-white border p-2 fnt-size14px"
                        styleClass="form-control fnt-size14px">
                      </p-dropdown>
                      <div *ngIf="(form?.get(field?.fieldName)?.touched || myForm.submitted) && form?.get(field?.fieldName)?.invalid"
                        class="text-danger fnt-size10px">
                        {{field.title}} {{'IS_REQUIRED' | translate}}
                      </div>
                    </div>
                    <div *ngIf="field.fieldName.startsWith('address') && process !== this.unEmbeddedPharma" class="form-group">
                        <label *ngIf="field.type === this.fieldType.ADDRESS_HOUSE_NO_TEXT" class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">{{field.title}}
              <span class="text-danger">*</span>
            </label>
                        <div *ngIf="field.type === this.fieldType.ADDRESS_HOUSE_NO_TEXT" class="form-group">
                            <input (keyup)="userInput(field.fieldName, $event)" formControlName="{{field.fieldName}}" type="text" class="form-control fnt-size12px fnt_Medium bg-porcelain-white" placeholder="{{field.placeHolder}}" [ngClass]="{
                    'border border-danger': form.get(field.fieldName).touched && form.get(field.fieldName).invalid,
                    'border-0': (form.get(field.fieldName).untouched || form.get(field.fieldName).valid)
                                && (!myForm.submitted)
                  }">
                            <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
              &&
              form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                                {{field.title}} {{'IS_REQUIRED' | translate}}
                            </div>
                        </div>
                        <div *ngIf="field.type === this.fieldType.ADDRESS_SUBDIVISION_TEXT && !checkForServierProcess()" class="form-group">
                            <label *ngIf="field.type === this.fieldType.ADDRESS_SUBDIVISION_TEXT" class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">{{field.title}}
                <span class="text-danger">*</span>
              </label>
                            <input (keyup)="userInput(field.fieldName, $event)" formControlName="{{field.fieldName}}" type="text" class="form-control fnt-size12px fnt_Medium bg-porcelain-white" placeholder="{{field.placeHolder}}" [ngClass]="{
                    'border border-danger': form.get(field.fieldName).touched && form.get(field.fieldName).invalid,
                    'border-0': (form.get(field.fieldName).untouched || form.get(field.fieldName).valid)
                                && (!myForm.submitted)
                  }">
                            <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
                &&
                form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                                {{field.title}} {{'IS_REQUIRED' | translate}}
                            </div>
                        </div>
                        <div *ngIf="field.type === this.fieldType.SEARCH && field.fieldName === 'addressRegion'" class="form-group disabling-loader ">
                            <label *ngIf="field.type === this.fieldType.SEARCH && field.fieldName === 'addressRegion'" class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">{{field.title}}
                <span class="text-danger">*</span>
              </label>
                            <p-autoComplete (keyup)="userInput(field.fieldName, $event)" [inputStyleClass]="'form-control fnt-size12px fnt_Medium bg-porcelain-white'" [suggestions]="regions" [styleClass]="form.get(field.fieldName).touched && form.get(field.fieldName).invalid ? 'pcomponent-invalid' : 'border-0'"
                                [placeholder]="field.placeHolder" [minLength]="3" (completeMethod)="search($event, 'region')" formControlName="{{field.fieldName}}" field="name">
                                <ng-template let-region pTemplate="item">
                                    <div [innerHTML]="region.name | highlight: toHighlight"></div>
                                </ng-template>
                            </p-autoComplete>
                            <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
                &&
                form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                                {{field.title}} {{'IS_REQUIRED' | translate}}
                            </div>
                        </div>
                        <div *ngIf="field.type === this.fieldType.SEARCH && field.fieldName === 'addressCity'" class="form-group disabling-loader">
                            <label *ngIf="field.type === this.fieldType.SEARCH && field.fieldName === 'addressCity'" class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">{{'City' | translate}}
                <span class="text-danger">*</span>
              </label>
                            <p-autoComplete (keyup)="userInput(field.fieldName, $event)" [inputStyleClass]="'form-control fnt-size12px fnt_Medium bg-porcelain-white'" [suggestions]="cities" [styleClass]="form.get(field.fieldName).touched && form.get(field.fieldName).invalid ? 'pcomponent-invalid' : 'border-0'"
                                [placeholder]="field.placeHolder" [minLength]="3" (completeMethod)="search($event, 'city')" formControlName="{{field.fieldName}}" field="name">
                                <ng-template let-city pTemplate="item">
                                    <div [innerHTML]="city.name | highlight: toHighlight"></div>
                                </ng-template>
                            </p-autoComplete>
                            <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
              &&
              form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                                {{field.title}} {{'IS_REQUIRED' | translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="field.type === this.fieldType.FILE" class="form-group">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0" [ngClass]="{
                'text-danger': (this.form.get(field.fieldName).invalid && this.selectedFileFlag
                                && (this.imageOnload || myForm.submitted))
              }">
              {{field.title}}
              <span *ngIf="field.fieldName !== 'nhsoProof'" class="text-danger">*</span>
            </label>
                        <input type="file" class="d-none" (click)="uploadImage.value = null" (change)="onSelectFile($event, field.fieldName)" #uploadImage accept=".png, .jpeg, .jpg">
                        <div *ngIf="this.form.get(field.fieldName).value !== ''" class="position-relative">
                            <img class="patient-id-card rounded" [src]="this.form.get(field.fieldName).value" alt="patient id card">
                            <img *ngIf="!this.form.disabled" class="cross-icon" src="assets/images/cross-icon.svg" (click)="removeFile($event, field.fieldName, -1)" alt="cross icon">
                        </div>
                        <button [disabled]="responseData.submitted" (click)="uploadImage.click()" class="btn btn-block btn-outline-success fnt-size12px fnt_Medium mt-1" type="button" [hidden]="this.form.get(field.fieldName).value">
              <div class="d-flex justify-content-center align-items-center">
                <i class="pi pi-image h6 mb-0 mr-1"></i>
                <span *ngIf="field.fieldName !== 'nhsoProof'">
                  {{"UPLOAD_ID_CARD" | translate}}
                </span>
                <span *ngIf="field.fieldName == 'nhsoProof'">
                  {{"UPLOAD_NHSO_PROOF" | translate}}
                </span>
              </div>
            </button>
                        <div *ngIf="(this.imageOnload || myForm.submitted)
              &&
              form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                            <div *ngIf="this.selectedFileFlag && this.form.get(field.fieldName).errors.required">Please upload a photo id.
                            </div>
                            <div *ngIf="this.selectedFileFlag && this.form.get(field.fieldName).errors.wrongFormat">This format is not valid
                            </div>
                            <div *ngIf="this.selectedFileFlag && this.form.get(field.fieldName).errors.sizeToBig">Size is greater than 10 MB.</div>
                        </div>
                        <div class="text-danger fnt-size10px" *ngIf="!this.idCardSizeValid && !myForm.submitted ">
                            {{"DOCUMENT_SIZE_ERR" | translate}}</div>
                    </div>
                    <div *ngIf="field.type === this.fieldType.MULTIPLE_FILE" class="form-group">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0" [ngClass]="{
                'text-danger': (this.form.get(field.fieldName).invalid && this.selectedFileFlag
                                && (this.imageOnload || myForm.submitted))
              }">
              <span [innerHTML]="field.title"></span>
              <span class="text-danger">*</span>
              <div class="text-grey-100 fnt-size8px" *ngIf="field.fieldName === 'discountUrls' ">
                {{"DISCOUNT_URLS_SUB_HEADER" | translate}}</div>
            </label>
                        <button [disabled]="responseData.submitted" (click)="uploadMultipleFile.click()" class="btn btn-block btn-outline-success fnt-size12px fnt_Medium p-1" [ngClass]="{'btn-outline-83134D': checkForOazisProcess()}" type="button">
              <div class="d-flex justify-content-center align-items-center">
                <i class="pi pi-image h6 mb-0 mr-1"></i>

                {{field.placeHolder}}
              </div>
            </button>
                        <input type="file" class="d-none" (click)="uploadMultipleFile.value = null" (change)="onSelectMultipleFile($event, field.fieldName)" #uploadMultipleFile accept=".png, .jpeg, .jpg" multiple="true">
                        <div class="position-relative" [hidden]="this.prescriptionImages?.length === 0">
                            <div class="row my-2">
                                <div *ngFor="let prescrptionImage of this.prescriptionImages; index as i" class="col-4">
                                    <dngx-full-view-galaria [images]="this.prescrptionImage" [circular]="false" [close]="true" [previewCloseOnClick]="true" [fullScreen]="true" [showItemNavigators]="false" [showThumbnails]="false" [showZoomIcon]="false" zoomIcon="assets/images/zoom-icon-white.svg"
                                        maskClass="bg-black-50">
                                    </dngx-full-view-galaria>
                                    <img *ngIf="!responseData.submitted" class="cross-icon" src="assets/images/cross-icon.svg" (click)="removeFile($event, field.fieldName, i)" alt="cross icon">
                                </div>
                            </div>
                        </div>
                        <div class="text-danger fnt-size10px" *ngIf="!this.isFileTypeValid && !myForm.submitted ">
                          {{"INVALID_FILE_TYPE" | translate}}</div>
                        <div class="text-danger fnt-size10px" *ngIf="!this.isprescriptionFileSizeValid && !myForm.submitted ">
                            {{"DOCUMENT_SIZE_ERR" | translate}}</div>
                        <div class="text-danger fnt-size10px" *ngIf="!this.isprescriptionFileLengthValid && !myForm.submitted && field.fieldName !== 'discountUrls' ">
                            {{"PRESCRIPTION_MAX_LENGTH" | translate}}</div>
                        <div class="text-danger fnt-size10px" *ngIf="!this.isprescriptionFileLengthValid && !myForm.submitted && field.fieldName === 'discountUrls' ">
                            {{"DISCOUNT_MAX_LENGTH" | translate}}</div>
                    </div>
                    <div *ngIf="field.fieldName.startsWith('program')" class="form-group drug-search">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">{{ responseData.formType === 'PROGRAM_INFO' && checkForOazisProcess() ? this.translate.instant('FA_TITLE_PROGRAM_NAME') : field.title}}
              <span class="text-danger">*</span>
            </label>
                        <div #selectedProgramDiv (click)="showFaceDetails()" class="mb-2 mt -1 fnt-size12px d-none selected-program">
                        </div>
                        <div *ngIf="field.type === this.fieldType.SEARCH" class="form-group">
                            <div #programSearch>
                                <p-autoComplete [inputStyleClass]="'form-control fnt-size12px fnt_Medium bg-porcelain-white'" [suggestions]="programs" [styleClass]="form.get(field.fieldName).touched && form.get(field.fieldName).invalid ? 'pcomponent-invalid' : 'border-0'" [placeholder]="responseData.formType === 'PROGRAM_INFO' && checkForOazisProcess() ? this.translate.instant('FA_PLACEHOLDER_PROGRAM_NAME') : field.placeHolder"
                                    (completeMethod)="search($event, 'program')" formControlName="{{field.fieldName}}" field="Program.Name" [minLength]="0" [completeOnFocus]="true" [forceSelection]="true" (onSelect)="onSelectProgram($event)" [inputId]="'searchdrug'"
                                    emptyMessage="{{'NO_DATA_AVAILABLE' | translate}}" (showEmptyMessage)="true">
                                    <ng-template let-program pTemplate="item">
                                        <div class="mb-1 px-2 fnt-size12px mb-2 text-break" [innerHTML]="program.Program.Name  | highlight: toHighlight"></div>
                                        <div *ngIf="!checkForOazisProcess()">
                                            <ng-container *ngFor="let test of program.Program.Drugs let isLast=last">
                                                <div class="px-2">
                                                    <span [innerHTML]="test.GenericName"></span>
                                                    <span>(</span>
                                                    <span [innerHTML]="test.BrandName"></span>
                                                    <span>)</span>
                                                    <span>{{isLast ? '' : ', '}} </span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                </p-autoComplete>
                            </div>
                            <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
                &&
                form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
                                {{ responseData.formType === 'PROGRAM_INFO' && checkForOazisProcess() ? this.translate.instant('FA_TITLE_PROGRAM_NAME') : field.title}} {{'IS_REQUIRED' | translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="field.type === this.fieldType.NUMBER" class="form-group" [ngClass]="{'mt-2': i === 0 && field.fieldName !== 'verifyOtp'}">
                        <label class="fnt-size10px fnt_Medium text-rolling-stone-grey mb-0">
              <span>
                {{field.title}}
              </span>
              <span *ngIf="field.title" class="text-danger">*</span>
            </label>
                        <input (keyup)="userInput(field.fieldName, $event)" formControlName="{{field.fieldName}}" type="text" inputmode="numeric" class="form-control fnt-size12px fnt_Medium bg-porcelain-white" placeholder="{{field.placeHolder}}" (input)="validateNo($event)"
                            [ngClass]="{
                  'border border-danger': form.get(field.fieldName).touched && form.get(field.fieldName).invalid,
                  'border-0': (form.get(field.fieldName).untouched || form.get(field.fieldName).valid)
                              && (!myForm.submitted)
                }" [attr.max]="field.fieldName === 'verifyOtp'" dngxDigitOnly>
                        <div *ngIf="(form.get(field.fieldName).touched || myForm.submitted)
              &&
              form.get(field.fieldName).invalid" class="text-danger fnt-size10px">
              <span *ngIf="form.get(field.fieldName).errors?.pattern">
                  {{ (field.fieldName === 'verifyOtp' ? 'PLEASE_ENTER_VALID_OTP' : 'PLEASE_ENTER_VALID_DISOCUNT') | translate}}
                </span>
                <span *ngIf="!form.get(field.fieldName).errors?.pattern"> {{ field.fieldName === 'verifyOtp' ? 'OTP' : field.title}} {{'IS_REQUIRED' | translate}}</span>
                        </div>


                      <div *ngIf="field.fieldName === 'verifyOtp'" class="d-flex my-3 align-items-center">
                        <ng-container *ngIf="!isResendOtp">
                          <span class="text-rolling-stone-grey fnt-size10px mr-3">{{'DID_NOT_RECEIVED_CODE' | translate}}</span>
                          <span *ngIf="disableResendOtp" class="text-rolling-stone-grey fnt-size12px fnt_Weight_600 text-underline">{{'RESEND_OTP' | translate}}</span>
                          <span *ngIf="!disableResendOtp" class="text-83134D fnt-size12px fnt_Weight_600 text-underline cursor-pointer" (click)="resendOtp()">{{'RESEND_OTP' | translate}}</span>
                        </ng-container>
                        <ng-container *ngIf="isResendOtp">
                          <span class="text-rolling-stone-grey fnt-size10px">
                            {{'NOT_GET_OTP_CONTACT_US_AT' | translate}} <span class="text-83134D fnt-size12px fnt_Weight_600">02-0800666</span> ({{'CONTACT_SCHEDULE_MONDAY_TO_FRIDAY' | translate}})
                          </span>
                        </ng-container>
                      </div>
          
          
                    </div>
                </div>
                <button *ngIf="responseData.submitted && !this.responseData.isCareGiverExist" class="fnt-size12px fnt_Medium btn btn-block mt-3 btn btn-outline-success" type="button" [disabled]="!isEditable" (click)="editApplicationForm(responseData.index)">
          {{responseData.submitText}}
        </button>
                <button *ngIf="!responseData.submitted && !this.responseData.isCareGiverExist" class="fnt-size12px fnt_Medium btn btn-block mt-3 text-white border-0" [disabled]="this.isSubmissionOngoing || this.responseData.patientAlreadyExistsErr" [ngClass]="{'btn-success': isControlsValid() && !this.fileUploadStatus, 'btn-secondary': !isControlsValid() || this.fileUploadStatus , 'btn-83134D' : checkForOazisProcess() && isControlsValid() && !this.fileUploadStatus}"
                    type="submit">
          {{responseData.submitText}}
        </button>
                <button type="button" *ngIf="this.responseData.isCareGiverExist" class="fnt-size12px fnt_Medium btn btn-block mt-3 btn-outline-danger" [disabled]="this.isSubmissionOngoing || this.responseData.deleteCaregiverSub" (click)="this.application === 'HYBRID' ? deleteCaregiver(responseData.id, responseData.index, 'DELETE_CAREGIVER'): deleteEpapCaregiver(responseData.id, responseData.index, 'Delete Caregiver')">
          {{'DELETE_CAREGIVER' | translate}}
        </button>
                <button type="button" *ngIf="this.responseData.isCareGiverExist && this.application === 'HYBRID'" class="fnt-size12px fnt_Medium btn btn-block mt-3 btn-outline-success hybridGoBack d-flex justify-content-center align-content-center" [disabled]="this.isSubmissionOngoing || this.responseData.deleteCaregiverSub"
                    (click)="goBack(responseData.id, responseData.index, 'Go Back')">
          <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
            <path stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" d="m8.843 15-4.8-4.8 4.8-4.8" />
            <path stroke-linecap="round" stroke-width="1.5" d="M4.043 10.2h11.7" />
          </svg>
          {{'GO_BACK' | translate}}
        </button>
            </div>
            <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime | date:'shortTime' | lowercase}}
            </div>
        </form>
    </div>
</div>



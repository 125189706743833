<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{checkForSingaporeCountry() ? ("REPORTER_OR_TESTER_INFO" | translate) : ("REPORTER_INFORMATION" | translate)}}</h5>
  <p *ngIf="checkForSingaporeCountry()" class="fnt-size14px text-heading-grey mb-2">{{'REPORT_INFO_SUBHEADING'|translate}}</p>
  <form [formGroup]="reportedInfoForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{'REPORTER_TYPE' | translate}}
            <span class="text-danger">*</span>
            </span>
          </label>
          <select required class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
            formControlName="reporterType">
            <option value='' disabled selected class="d-none">
              {{'SELECT_REPORTER_TYPE' | translate}}
            </option>
            <option *ngFor="let form of reportedTypeList" [value]="form.name"
              [selected]="form.name === form.reporterType?.value" class="mt-5">
              {{form.name}}
            </option>
          </select>
          <div *ngIf="(form.reporterType.touched) && form.reporterType.invalid" class="text-danger fnt-size12px">
            {{'REPORTER_TYPE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"IF_OTHER_SPECIFIC" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="otherReporterType" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_PROFESSION' | translate}}" [maxlength]="checkForSingaporeCountry() ? 500 : 50" />
          </div>
          <div *ngIf="form.otherReporterType.touched && form.otherReporterType.invalid"
            class="d-none text-danger fnt-size12px">
            {{"IF_OTHER_SPECIFIC" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="checkForSingaporeCountry()">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'SAFETY_TEAM_WILLING_YES_NO' | translate }}
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="consentYes" value="Yes"
                formControlName="reporterSafetyContactText">
              <label class="radio-button-text cursor-pointer" for="consentYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="consentNo" value="No"
                formControlName="reporterSafetyContactText">
              <label class="radio-button-text cursor-pointer" for="consentNo">{{ 'NO' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"NAME" | translate}}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="reporterName" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_FULL_NAME' | translate}}" [maxlength]="getMaxLength()" />
          </div>
          <div *ngIf="(form.reporterName.touched) && form.reporterName.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{'NAME' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess() && !checkForSingaporeCountry()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"OCCUPATION" | translate}}
              <span class="text-danger" *ngIf="!checkForProcess()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="reporterOccupation" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_OCCUPATION' | translate}}" [maxlength]="50" />
          </div>
          <div *ngIf="(form.reporterOccupation.touched) && form.reporterOccupation.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{'OCCUPATION' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{ checkForSingaporeCountry() ? ('TELEPHONE_NO' | translate) : ('PHONE_NUMBER' | translate) }}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForServierProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="reporterMobileNumber" type="text" class="form-control fnt-size14px"
              [placeholder]="checkForSingaporeCountry() ? ('ENTER_TELEPHONE_NO' | translate) : ('ENTER_PHONE_NUMBER' | translate)"
              [maxlength]="20"/>
          </div>
          <div *ngIf="(form.reporterMobileNumber.touched) && form.reporterMobileNumber.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{'PHONE_NUMBER' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{checkForSingaporeCountry() ? ("EMAIL_ADDRESS" | translate) : ("EMAIL_ID" | translate)}}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="reporterEmail" type="text" class="form-control fnt-size14px"
            [placeholder]="checkForSingaporeCountry() ? ('ENTER_EMAIL_ADDRESS' | translate) : ('ENTER_EMAIL_ID' | translate)" [maxlength]="getMaxLength()" />
          </div>
          <div *ngIf="(form.reporterEmail.touched) && form.reporterEmail.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{'EMAIL_ID' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"FAX" | translate}}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="reporterFax" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_FAX' | translate}}" [maxlength]="20" />
          </div>
          <div *ngIf="(form.reporterFax.touched) && form.reporterFax.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{'FAX' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="checkForSingaporeCountry()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"REPORTER_COUNTRY"| translate}}
            </span>
          </label>
          <select class="form-control fnt-size12px fnt_Medium bg-porcelain-white" formControlName="reporterCountry">
            <option *ngFor="let country of countryList" [value]="country.name" [selected]="country.name === form.reporterCountry?.value">
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-8" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              
              {{checkForSingaporeCountry() ? ("POSTAL_ADDRESS" | translate) : ("ADDRESS" | translate)}}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <textarea formControlName="reporterAddress" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_ADDRESS' | translate}}" [maxlength]="checkForSingaporeCountry() ? 1000 : 100">
            </textarea>
          </div>
          <div *ngIf="(form.reporterAddress.touched) && form.reporterAddress.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{'ADDRESS' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="!checkForServierProcess() && !checkForSingaporeCountry()">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'CONSENT_CONTACT_TREATING_PHYSICIAN' | translate }}
            <span class="text-danger">*</span>
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="consentYes" value="Yes"
                formControlName="consentToContactPhysician">
              <label class="radio-button-text cursor-pointer" for="consentYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="consentNo" value="No"
                formControlName="consentToContactPhysician">
              <label class="radio-button-text cursor-pointer" for="consentNo">{{ 'NO' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="consentNA" value="Not Applicable"
                formControlName="consentToContactPhysician">
              <label class="radio-button-text cursor-pointer" for="consentNA">{{ 'NOT_APPLICABLE' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 pb-3">
        <p class="fnt-size12px" *ngIf="!checkForServierProcess() && !checkForSingaporeCountry()">
          {{'IF_YES_PLEASE_INFORM_CONTACT_DETAILS' | translate}}
        </p>
      </div>
      <div class="col-12 pb-3">
        <p class="fnt-size12px" *ngIf="checkForSingaporeCountry()">
          {{'TREATMENT_HCP_DIF_REPORTER' | translate}}
        </p>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px">
            <span>
              {{checkForServierProcess() ? ("NAME_OF_PHYSICIAN" | translate) : ("NAME" | translate)}}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="physicianName" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_FULL_NAME' | translate}}" [maxlength]="checkForSingaporeCountry() ? 200 : 100" />
          </div>
          <div *ngIf="form.physicianName.touched && form.physicianName.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"NAME" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{ checkForSingaporeCountry() ? ('TELEPHONE_NO' | translate) : ('PHONE_NUMBER' | translate) }}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="physicianMobileNumber" type="text" class="form-control fnt-size14px"
            [placeholder]="checkForSingaporeCountry() ? ('ENTER_TELEPHONE_NO' | translate) : ('ENTER_PHONE_NUMBER' | translate)" [maxlength]="20"/>
          </div>
          <div *ngIf="form.physicianMobileNumber.touched && form.physicianMobileNumber.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"PHONE_NUMBER" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{ checkForSingaporeCountry() ? ('EMAIL_ADDRESS' | translate) : ('EMAIL_ID' | translate) }}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="physicianEmail" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_EMAIL_ID' | translate}}"
              [placeholder]="checkForSingaporeCountry() ? ('ENTER_EMAIL_ADDRESS' | translate) : ('ENTER_EMAIL_ID' | translate)"
               [maxlength]="checkForSingaporeCountry() ? 200 : 100" />
          </div>
          <div *ngIf="form.physicianEmail.touched && form.physicianEmail.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"EMAIL_ID" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"SPECIALITY" | translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <p-dropdown class="dropdown_speciality w-100 pr-2 " [options]="specialityOptions" optionLabel="name" [(ngModel)]="selectedSpeciality"
              [ngModelOptions]="{standalone: true}" (onChange)="setSpecialityToForm(($event).value)"
              [placeholder]="('SELECT_SPECIALITY' | translate)"
              [autoDisplayFirst]="false" panelStyleClass="bg-white border p-2 fnt-size14px"
              styleClass="form-control fnt-size14px"
              [disabled]="disabled" (keyup.backspace)="selectedSpeciality = null;clearFormField('physicianSpeciality')">
            </p-dropdown>
            <div *ngIf="form?.physicianSpeciality?.touched && form?.physicianSpeciality?.invalid"
              class="text-danger fnt-size12px">
              {{"SPECIALITY" | translate}} {{'IS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"FAX" | translate}}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input (keyup)="userInput('fax', $event)" formControlName="fax" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_FAX' | translate}}" [maxlength]="20" />
          </div>
          <div *ngIf="form.fax.touched && form.fax.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"FAX" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{checkForSingaporeCountry() ? ("POSTAL_ADDRESS" | translate) : ("ADDRESS" | translate)}}
              <span class="text-danger" *ngIf="!checkForProcess() && !checkForSingaporeCountry()">*</span>
            </span>
          </label>
          <div class="position-relative">
            <textarea formControlName="physicianAddress" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_ADDRESS' | translate}}" [maxlength]="checkForSingaporeCountry() ? 200 : 100">
            </textarea>
          </div>
          <div *ngIf="form.physicianAddress.touched && form.physicianAddress.invalid && !checkForProcess()" class="text-danger fnt-size12px">
            {{"ADDRESS" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  APPROVER_CONSTANTS,
  COUNTRIES_LIST,
  COUNTRIES_NAME,
  FILE_MODULE,
  FOLDER_CONSTANTS,
  IMAGE_TYPES,
  LOCAL_STORAGE_COUNTRY,
  MAX_FILE_SIZE,
  MIME_TYPES,
  NOVARTIS_PROCESS,
  PATTERN,
  TH_SERVIER_PROCESS
} from 'projects/nga-PAP/src/app/constant/app.constant';

import { FileService } from 'projects/nga-PAP/src/app/file.service';
import { Image } from 'projects/nga-PAP/src/app/models/sign-up-chatbot.model';
import * as FileAction from '../../../../../store/actions/file/file.action';
import * as FileSelector from '../../../../../store/selectors/file/file.selectors';
import { Store } from '@ngrx/store';
import { HelperService } from 'projects/nga-PAP/src/app/helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pap-relevant-laboratory-test-data-form',
  templateUrl: './relevant-laboratory-test-data-form.component.html',
  styleUrls: ['./relevant-laboratory-test-data-form.component.scss']
})
export class RelevantLaboratoryTestDataFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;
  /**
   * Form pediatric report.
   */
  public relevantLabTestForm: FormGroup;
  public disabled: boolean;
  public fileErrorMessage = '';
  public isFileTypeValid = true;
  public prescriptionFiles: string[] = [];
  public prescriptionImages: Image[][] = [];
  public isprescriptionFileLengthValid = true;
  public isprescriptionFileSizeValid = true;
  public fileUploadUrlSub: Subscription;

  public prescriptionDrugCard = [];
  public prescriptionEditCard = [];


  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.relevantLabTestForm.controls; }
  get formValidity(): boolean { return this.relevantLabTestForm.valid; }
  get formValues(): { relevantLabTestForm: any; } {
    return {
      relevantLabTestForm: this.relevantLabTestForm.value
    };
  }

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder,
              public fileService: FileService,
              private store: Store, private helper: HelperService, ) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.relevantLabTestForm = this.fb.group({
      possibleRisk: new FormControl(
        {value : this.formData?.possibleRisk ? this.formData?.possibleRisk : '',
          disabled: this.disabled},
        [Validators.maxLength(500)]),
        relevantFiles: new FormControl({value : this.formData?.relevantFiles ? this.formData?.relevantFiles : '',
          disabled: this.disabled})
    });

    this.getDrugPresignedUrl();
  }

  /**
   * Check if process is for servier flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }

  /**
   * generate file name using the given time parameter
   */
  getFileNameCurrent(time: number): string {
    return `doctor/document/${time}-1-prescription`;
  }
  getPrescriptionName(patientToken: string, time: number): string {
    if (patientToken) {
      return `doctor/document/${patientToken}-${time}-prescription`;
    }
  }


  /**
   * to get a presigned URL for the file based on the file type, module, and content type.
   * The URL is used to securely upload files to the server.
   */
  fetchGetPresignedUrl(fileType: string, module: string, fileName: string, contentType: string): Promise<string> {
    const time = new Date().getMilliseconds();
    return new Promise((resolve, reject) => {
      const countryCode = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
      let countryName = COUNTRIES_NAME[countryCode];
      if (!countryName) {
        countryName = COUNTRIES_LIST.PHILLIPPINS.name;
      }
      this.store.dispatch(FileAction.loadFilePresignedUrl({
        endPoint: APPROVER_CONSTANTS.FILE_SERVICE_URL,
        fileType,
        fileName,
        contentType,
        fileMethod: 'false',
        country: this.helper.getCurrentCountryString(),
        time,
        module
      }));
      this.fileUploadUrlSub = this.store.select(FileSelector.getFilePresignedUrl).subscribe(url => {
        if (url != null) {
          resolve(url);
          this.store.dispatch(FileAction.resetFilePresignedUrl({
            presignedUrl: null
          }));
        }
      });
    });
  }


  /**
   *  Retrieves presigned URLs for prescription files and processes them.
   */
  async getDrugPresignedUrl(): Promise<void> {
    /**
     * return if the prescription edit card already exists
     */
    if (this.prescriptionEditCard?.length > 0 || this.prescriptionFiles?.length > 0) {
      return;
    }
    const presignedUrls = [];
    if (this.formData?.relevantFiles?.length > 0) {
      this.prescriptionFiles.push(...this.formData?.relevantFiles);
    }

    for (const relevantFile of this.formData?.relevantFiles) {
      const fileExtension = relevantFile.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
      const patientToken = relevantFile.split('/').pop().split('-')[0];
      const fileTime: string = relevantFile.split('-prescription').shift().split(`${patientToken}-`).pop();
      let contentType = '';
      switch (fileExtension.toLowerCase()) {
        case IMAGE_TYPES.jpeg:
          contentType = MIME_TYPES.JPEG;
          break;
        case IMAGE_TYPES.jpg:
          contentType = MIME_TYPES.JPG;
          break;
        case IMAGE_TYPES.png:
          contentType = MIME_TYPES.PNG;
          break;
        case IMAGE_TYPES.pdf:
          contentType = MIME_TYPES.PDF;
          break;
        case IMAGE_TYPES.xls:
          contentType = MIME_TYPES.XLS;
          break;
        case IMAGE_TYPES.xlsx:
          contentType = MIME_TYPES.XLSX;
          break;
      }
      const fileName = this.getPrescriptionName(patientToken, parseInt(fileTime, 10));
      const getPresignedUrl = await this.fetchGetPresignedUrl(null, FILE_MODULE.NA, fileName, contentType);
      presignedUrls.push(getPresignedUrl);
    }
    
    presignedUrls.forEach(url => {
      const fileExtension = this.getFileExtensionFromPresignedUrl(url);
      if ([IMAGE_TYPES.pdf, IMAGE_TYPES.xls, IMAGE_TYPES.xlsx].includes(fileExtension)) {
        this.prescriptionEditCard.push({
          type: 'DOCUMENT',
          source: url,
          thumbnailImageSrc: 'assets/images/pdf.svg',
          alt: 'relevant file'
        });
      } else {
        this.prescriptionEditCard.push({
          type: 'IMAGE',
          source: url,
          thumbnailImageSrc: url,
          alt: 'relevant file'
        });
      }
    });
  }

  getFileExtensionFromPresignedUrl(preSignedUrl: string): string {
    // Extract the part before the query parameters
    const urlPath = preSignedUrl.split('?')[0];

    // Get the last part after the last dot `.`
    return urlPath.split('.').pop() || '';
  }


  /**
   * used to handle multiple files
   */
async onSelectMultipleFile(event, controlName: string): Promise<void> {
  const countryCode = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
  const countryName = COUNTRIES_NAME[countryCode] || COUNTRIES_LIST.PHILLIPPINS.name;

  const allowedFileTypes = ['image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf', 'application/vnd.ms-excel'];

  if (!event.target.files || event.target.files.length === 0) {
      return;
  }

  for (const file of event.target.files) {
      const fileType = file.type;
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedFileTypes.includes(fileType) || fileExtension === 'jpg') {
          this.fileErrorMessage = 'Only jpeg/png/pdf/xls files allowed.';
          this.isFileTypeValid = false;
          return;
      }
  }

  this.isFileTypeValid = true;
  this.isprescriptionFileSizeValid = this.isFileSizeValid(event, MAX_FILE_SIZE);
  if (!this.isprescriptionFileSizeValid) {
      this.fileErrorMessage = 'Single File size must not exceed 20 MB';
      this.isprescriptionFileLengthValid = true;
      return;
  }

  const existingFilesCount = this.relevantLabTestForm.get(controlName).value?.length || 0;
  const newFilesCount = event.target.files.length;

  if (existingFilesCount + newFilesCount > 10) {
      this.fileErrorMessage = 'Only 10 attachments allowed';
      this.isprescriptionFileLengthValid = false;
      this.isprescriptionFileSizeValid = true;
      return;
  }

  this.fileErrorMessage = '';
  this.isprescriptionFileLengthValid = true;
  this.relevantLabTestForm.get(controlName).setValue('');

  for (const file of event.target.files) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileName = this.getFileNameCurrent(Date.now());
      const getPresignedUrl = await this.fileService.uploadFile(
          file,
          null,
          fileName,
          FILE_MODULE.NA,
          countryName
      );
      this.prescriptionFiles.push(`${fileName}.${fileExtension}`);

      if (file.type.startsWith('image/')) {
          this.prescriptionEditCard.push({
              type: 'IMAGE',
              source: getPresignedUrl,
              thumbnailImageSrc: getPresignedUrl,
              alt: 'image'
          });
      } else if (file.type === 'application/pdf') {
          this.prescriptionEditCard.push({
              type: 'DOCUMENT',
              source: getPresignedUrl,
              thumbnailImageSrc: 'assets/images/pdf.svg',
              alt: 'PDF file'
          });
      } else if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                 file.type === 'application/vnd.ms-excel') {
          this.prescriptionEditCard.push({
              type: 'DOCUMENT',
              source: getPresignedUrl,
              thumbnailImageSrc: 'assets/images/pdf.svg',
              alt: 'Excel file'
          });
      }
  }

  this.relevantLabTestForm.get(controlName).setValue(this.prescriptionFiles);
}


/**
 * to remove file
 */
  removeFile(event, controlName: string, index: number): void {
    if (index === -1) {
      this.relevantLabTestForm.get(controlName).setValue('');
    } else {
      this.prescriptionFiles.splice(index, 1);
      this.prescriptionEditCard.splice(index, 1);
      this.relevantLabTestForm.get(controlName).setValue(this.prescriptionFiles);
      if (this.prescriptionFiles.length < 1) {
        this.relevantLabTestForm.get(controlName).setValue('');
      }
    }
  }

  /**
   * function to validate filesize
   */
  isFileSizeValid(event: any, maxSize: number): boolean {
    if (event.target.files) {
      for (const item of event.target.files) {
        if (item.size > maxSize) {
          return false;
        }
      }
    }
    return true;
  }

  checkForNovartis(): boolean {
    return this.process === NOVARTIS_PROCESS;
  }
}

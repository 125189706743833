<div *ngIf="!showOtpComponent" class="col-11 col-md-5 h-100 hyridComponent mx-auto mt-4">
  <div class="border-bottom bg-white shadow-sm">
    <div class="text-center"> <img class="p-3" src="assets/images/logo.svg" alt="logo"></div>

    <div class="card-body bg-white shadow-sm  border-top ">
      <h5 class="card-title font-weight-bold fnt-size20px ">Welcome to Patient Access Program Service</h5>
      <p class="card-text signup-message fnt-size15px mb-1">Before registering for a new patient, please enter your
        mobile number for verification.</p>
      <form [formGroup]="mobileForm" (ngSubmit)="onSubmitLoginForm()">
        <div class="mb-2 round">
          <label for="mobileNumber" class="font-size15px fnt_Medium">Mobile Number <span
            class="text-danger">*</span></label>
          <div class="d-flex attendant-login-dropdown">
            <p-dropdown class="d-flex align-items-center pr-2  bg-rolling-grey"
              [options]="countries" formControlName="countryCode" [optionValue]="'countryCode'">
              <ng-template let-item pTemplate="selectedItem">
                <div class="country-item-value" *ngIf="this.mobileForm.get('countryCode')">
                  <div class="fnt-size12px fnt_Medium text-rolling-stone-grey text-center mr-1">
                    <img [src]="item.logo" class="flag mr-1" onerror="this.src='assets/images/logo.svg'"
                      alt="country flag" />
                    +{{this.mobileForm.get('countryCode').value}}
                  </div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="d-flex justify-content-between align-items-center p-1">
                  <div class="country-item-value d-flex">
                    <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                      alt="country flag" />
                    <div class="fnt-size12px text-abbey-grey mr-2"
                      [ngClass]="{'font-weight-bold': this.mobileForm.get('countryCode').value === item.countryCode}">
                      {{item.name}}
                    </div>
                    <div class="fnt-size12px text-abbey-grey"
                      [ngClass]="{'font-weight-bold': this.mobileForm.get('countryCode').value === item.countryCode}">
                      +{{item.countryCode}}
                    </div>
                  </div>
                  <img *ngIf="this.mobileForm.get('countryCode').value === item.countryCode"
                    src="assets/images/checkbox.svg" alt="green checked checkbox">
                </div>
              </ng-template>
            </p-dropdown>
            <div class="w-100 p-0">
             <input type="string" id="phone_number" class="mobile-input  w-100" placeholder="Enter Mobile Number" [autocomplete]="'off'"
              (keypress)="validateNumberLength($event)" (input)="onInputChange($event)" [maxLength]="15"
              formControlName="phone_number">
            </div>
          </div>
        </div>
        <div *ngIf="this.apiErrorMsg" class="text-danger">
          {{apiErrorMsg}}
        </div>
        <div *ngIf="mobileForm.get('phone_number')?.hasError('required') && mobileForm.get('phone_number').touched" class="text-danger mb-1">
          Mobile number is required
        </div>
        <div *ngIf="this.mobileForm.get('phone_number')?.hasError('minlength')" class="text-danger">
          Mobile number must be 8 to 15 digits
        </div>

        <button [ngClass]="isValid ? 'btn btn-block btn-success' : 'btn btn-block btn-secondary'" type="submit"
          [disabled]="!isValid || showLoader" class="d-flex align-items-center justify-content-center gap-2">
          <span class="mx-2">Get OTP</span>
          <span class="spinner-border text-light" role="status" *ngIf="showLoader">
          </span>
        </button>
      </form>

    </div>
  </div>

</div>

<div *ngIf="showOtpComponent" class="col-11 col-md-5 h-100 hyridComponent mx-auto mt-4">
  <div class="text-center bg-white shadow-sm border ">
    <img class="p-3" src="assets/images/logo.svg" alt="docquitycare logo">
  </div>
  <div class="card-body bg-white shadow-sm border">
    <div class="d-flex">
    <button type="button" class="btn p-2" (click)="backEditAttendantLoginPage()">
      <svg class="position-absolute mt-1" width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.07033 0.21967C6.36322 0.512563 6.36322 0.987437 6.07033 1.28033L1.81121 5.53945L6.06978 9.78912C6.36297 10.0817 6.36347 10.5566 6.07088 10.8498C5.7783 11.143 5.30342 11.1435 5.01022 10.8509L0.220224 6.07088C0.0793291 5.93028 0.000104053 5.73944 1.0254e-07 5.54039C-0.000103908 5.34134 0.0789216 5.15042 0.21967 5.00967L5.00967 0.21967C5.30256 -0.0732233 5.77744 -0.0732233 6.07033 0.21967Z" fill="#444445"/>
      </svg>
      <svg class="marginB48rem" width="18" height="3" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.930176 1.54297C0.930176 1.12876 1.26596 0.792969 1.68018 0.792969H16.7501C17.1643 0.792969 17.5001 1.12876 17.5001 1.54297C17.5001 1.95718 17.1643 2.29297 16.7501 2.29297H1.68018C1.26596 2.29297 0.930176 1.95718 0.930176 1.54297Z" fill="#444445"/>
      </svg>
    </button></div>
    <h5 class="card-title">Verify Your Number</h5>
    <form [formGroup]="otpForm" (ngSubmit)="enterConfirmOtp()">
      <div class="mx-2 pt-2">
        <p class="m-1 text-secondary-grey fnt_Medium fnt-size15px ">A 4-digit code has been sent to your mobile
          number</p><span><img class="verifyScreenFlag" src="assets/images/singapore-signup-icon.png" alt=""> +65 {{this.maskedLoginInput}}</span>
        <button type="button" class="btn text-success fnt-size12px p-0" (click)="backEditAttendantLoginPage()">
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-pencil m-1"
            viewBox="0 0 16 16">
            <path
              d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
          </svg>
          <span>{{ "EDIT" | translate }}</span>
        </button>
        <div class="d-flex ">
        </div>
        <div class="form-group d-flex w-100 mt-4 otp-input-container">

          <input #otp1 type="text" inputmode="numeric" (keyup)="keytab($event, 1)" dngxDigitOnly formControlName="otp1"
            class="form-control otpInputBox text-center bg-rolling-grey" required name="otp1" maxlength="1" [ngClass]="{
                    'border-danger': this.apiErrorMsg
                  }">

          <input #otp2 type="text" inputmode="numeric" (keyup)="keytab($event, 2)" dngxDigitOnly formControlName="otp2"
            class="form-control otpInputBox text-center px-2 bg-rolling-grey" required name="otp2" maxlength="1"
            [ngClass]="{
                    'border border-danger': this.apiErrorMsg
                  }">

          <input #otp3 type="text" inputmode="numeric" (keyup)="keytab($event, 3)" dngxDigitOnly formControlName="otp3"
            class="form-control otpInputBox text-center px-2 bg-rolling-grey" required name="otp3" maxlength="1"
            [ngClass]="{
                    'border-danger': this.apiErrorMsg
                  }">

          <input #otp4 type="text" inputmode="numeric" (keyup)="keytab($event, 4)" dngxDigitOnly formControlName="otp4"
            class="form-control otpInputBox text-center pl-2 bg-rolling-grey" required name="otp4" maxlength="1"
            [ngClass]="{
                    'border-danger': this.apiErrorMsg
                  }">

        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="text-rolling-stone-grey fnt-size14px fnt_Regular" *ngIf="counterValue !== 0 && !this.apiErrorMsg">
              {{ "PLEASE_WAIT_FOR_OTP_DELIVERED" | translate }}
            </div>
            <div *ngIf="counterValue === 0 || this.apiErrorMsg">
              <button *ngIf="this.verifyOtpCnt < 3" (click)="resendOtp()" type="button"
                class="btn text-success fnt-size12px px-0">{{'RESEND_OTP' | translate}}</button>
            </div>
          </div>
          <div *ngIf="!this.apiErrorMsg">
            <ng-container [dngxTimer]="counter" [interval]="interval" (value)="receiveCounterValue($event)">
              <span *ngIf="counter > 0" class="float-left mr-2">
                <span  id="countdown" class="d-flex align-items-center">
                  <span class="w-100 fnt-size10px medium-font-weight">
                    {{ counterValue }}</span>
                  <svg>
                    <circle class="greyCircle" r="10" cx="12" cy="12"></circle>
                    <circle class="greenCircle" r="10" cx="12" cy="12"></circle>
                  </svg>
                </span>
              </span>
            </ng-container>
          </div>
          <div class="text-danger" *ngIf="(this.verifyOtpCnt > 2 && this.apiErrorMsg)">
            OTP verification failed. Try a new mobile number
          </div>
          <div class="text-danger" *ngIf="this.apiErrorMsg && this.verifyOtpCnt < 3">
            {{this.apiErrorMsg}}
          </div>
        </div>
        <button [disabled]="!this.otpForm.valid || this.verifyOtpCnt > 2 || showLoader" type="submit"
          class="btn btn-success btn-block mt-3 d-flex align-items-center justify-content-center">
          <span class="mx-2">Verify OTP</span>
          <span class="spinner-border text-light" role="status" *ngIf="showLoader"></span>
        </button>
      </div>
    </form>
  </div>
</div>